import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, dialogFooterContent } from "../../common/globals";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import ProcessImportErrors from "../../components/ProcessImportErrors";

export default function ImportCompanies() {
  const baseURL = BASE_URL + "import/company";

  const [fileC, setFileC] = useState(null);
  const [fileP, setFileP] = useState(null);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);

  // authentication details
  const { auth } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fileC === null && fileP === null) {
      alert("Fisierul de importat lipseste");
      return;
    }
    setProcessingDialog(true);
    setValidationErrors(null);
    const formData = new FormData();
    if (fileC !== null) {
      formData.append("files", fileC);
    }
    if (fileP !== null) {
      formData.append("files", fileP);
    }
    axios
      .post(baseURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        setProcessingDialog(false);
        if (null !== res.data && res.data.statusType !== "OK") {
          setValidationErrors(res.data);
        } else {
          setProcessedDialog(true);
        }
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button class="buttonClassGreen">Importă firme/puncte de lucru</button>
      <div class="spaceDiv" />
      <Link to={"/company/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyList)} alt="Listă firme" title="Listă firme" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.CompanyImportDisabled)}
        alt="Importă firme/puncte de lucru"
        title="Importă firme/puncte de lucru"
      />
      <form class="formArea">
        {/* File */}
        <div class="row">
          <div class="column">
            Firme şi puncte de lucru:
            <br />
            <input type="file" onChange={(e) => setFileC(e.target.files[0])} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {/* File */}
        <div class="row">
          <div class="column">
            Personal firme şi puncte de lucru:
            <br />
            <input type="file" onChange={(e) => setFileP(e.target.files[0])} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {/* Submit Buttons */}
        {processingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.CompanySaveAll)}
                alt="Importă"
                title="Importă"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
        {null !== validationErrors && null !== validationErrors.statusType && validationErrors.statusType !== "OK" ? (
          <ProcessImportErrors validationErrors={validationErrors} />
        ) : null}
      </form>
      <div class="spaceDiv" />
      <Link to={"/company/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyList)} alt="Listă firme" title="Listă firme" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.CompanyImportDisabled)}
        alt="Importă firme/puncte de lucru"
        title="Importă firme/puncte de lucru"
      />
      <div class="spaceDiv" />
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
    </div>
  );
}
