import React from "react";
import { useNavigate } from "react-router-dom";

const GenerateButton = (props) => {
  const { status, sessionUuid, companyUuid } = props;

  const navigate = useNavigate();

  const handleGenerateTests = async (sessionUuid, companyUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    sessionStorage.setItem("companyUuid", companyUuid);
    navigate("/worker/generatedocstests");
  };

  const handleGenerateCertificates = async (sessionUuid, companyUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    sessionStorage.setItem("companyUuid", companyUuid);
    navigate("/worker/generatedocscertificates");
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/worker/generatedocsview");
  };

  return (
    <>
      {status === "instructions" ? (
        <>
          <button class="longButtonClassGray" onClick={(e) => handleGenerateTests(sessionUuid, companyUuid, e)}>
            Nu au fost generate documentele de testare
          </button>
          <button class="longButtonClassGray" onClick={(e) => handleViewDocuments(sessionUuid, e)}>
            Vezi documentele generate
          </button>
        </>
      ) : null}
      {status === "tests" ? (
        <>
          <button class="longButtonClassGray" onClick={(e) => handleGenerateCertificates(sessionUuid, companyUuid, e)}>
            Nu au fost generate documentele de certificare
          </button>
          <button class="longButtonClassGray" onClick={(e) => handleViewDocuments(sessionUuid, e)}>
            Vezi documentele generate
          </button>
        </>
      ) : null}
      {status === "certificates" ? (
        <button class="longButtonClassGray" onClick={(e) => handleViewDocuments(sessionUuid, e)}>
          Documentele au fost generate - vezi documentele
        </button>
      ) : null}
    </>
  );
};

export default GenerateButton;
