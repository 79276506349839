import { Dialog } from "primereact/dialog";
import React from "react";

const DialogDataNotProcessed = (props) => {
  const { isVisible, footer } = props;

  return (
    <Dialog header="Eroare" visible={isVisible} footer={footer} style={{ width: "350px" }}>
      <p className="m-0">Informaţiile NU au fost salvate cu succes!</p>
    </Dialog>
  );
};

export default DialogDataNotProcessed;
