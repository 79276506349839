// prime core css
import "primereact/resources/primereact.min.css";
// prime theme css
import "primereact/resources/themes/nova/theme.css";
// custom css
import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ClientStatistics() {
  const statisticsURL = BASE_URL + "client/statistics";
  const companyListURL = BASE_URL + "company/listsimplecompanies";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const [statistics, setStatistics] = useState([]);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Alege firmă de lucru",
      label: "Alege firmă de lucru",
    },
  ];

  useEffect(() => {
    axios
      .post(
        statisticsURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setStatistics(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert("Configuratia certificarii nu a putut fi citita!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    sessionStorage.setItem("mainCompanySessionUuid", e.target.value);
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Statistici</button>
      <div class="spaceDiv" />
      <div class="row">
        <div class="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div class="row">
        <div class="column">Ai {statistics.nrOfCompanies} firme înregistrate!</div>
      </div>
      <div class="row">
        <div class="column">
          <Link to={"/company/list"}>
            <img src={require("../../static/images/buttons/" + ICONS.CompanyList)} alt="Listă firme" title="Listă firme" />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/company/register"}>
            <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
          </Link>
        </div>
      </div>

      <div class="row">
        <div class="column">Ai {statistics.nrOfBranches} puncte de lucru înregistrate!</div>
      </div>
      <div class="row">
        <div class="column">
          <Link to={"/companyBranch/list"}>
            <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchList)} alt="Listă puncte lucru" title="Listă puncte lucru" />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/companyBranch/register"}>
            <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAdd)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
          </Link>
        </div>
      </div>

      <div class="row">
        <div class="column">Ai {statistics.nrOfOperators} deservenți înregistrați!</div>
      </div>
      <div class="row">
        <div class="column">
          <Link to={"/operator/list"}>
            <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/operator/register"}>
            <img src={require("../../static/images/buttons/" + ICONS.OperatorAdd)} alt="Adaugă deservent" title="Adaugă deservent" />
          </Link>
        </div>
      </div>

      <div class="row">
        <div class="column">Ai {statistics.nrOfWorkers} deservenți auxiliari înregistrați!</div>
      </div>
      <div class="row">
        <div class="column">
          <Link to={"/worker/list"}>
            <img
              src={require("../../static/images/buttons/" + ICONS.WorkerList)}
              alt="Listă deservenți auxiliari"
              title="Listă deservenți auxiliari"
            />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/worker/register"}>
            <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
          </Link>
        </div>
      </div>

      <div class="row">
        <div class="column">Ai {statistics.nrOfLiftingEquipments} echipamente de ridicat înregistrate!</div>
      </div>
      <div class="row">
        <div class="column">
          <Link to={"/equipment/list"}>
            <img
              src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingList)}
              alt="Listă echipamente de ridicat"
              title="Listă echipamente de ridicat"
            />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/equipment/addlifting"}>
            <img
              src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
              alt="Adaugă echipament de ridicat"
              title="Adaugă echipament de ridicat"
            />
          </Link>
        </div>
      </div>

      <div class="row">
        <div class="column">Ai {statistics.nrOfPressureEquipments} echipamente sub presiune înregistrate!</div>
      </div>
      <div class="row">
        <div class="column">
          <Link to={"/equipment/list"}>
            <img
              src={require("../../static/images/buttons/" + ICONS.EquipmentPressureList)}
              alt="Listă echipamente sub presiune"
              title="Listă echipamente sub presiune"
            />
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to={"/equipment/addpressure"}>
            <img
              src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
              alt="Adaugă echipament sub presiune"
              title="Adaugă echipament sub presiune"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
