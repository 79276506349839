import "../../static/css/pageLayout.css";

import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, dialogFooterContent } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function EquipmentGenerateDocsOrder() {
  const instructionsConfigURL = BASE_URL + "equipmentdocuments/get/list/configs";
  const instructionsConfigSaveURL = BASE_URL + "equipmentdocuments/generate/order";

  const uuid = sessionStorage.getItem("equipmentSessionUuid");

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  const [values, setValues] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  useEffect(() => {
    axios
      .post(
        instructionsConfigURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.length; i++) {
          data = { ...data, ["urgent" + i]: "" };
          data = { ...data, ["rsvtiPerson" + i]: "" };
          data = { ...data, ["contactPerson" + i]: "" };
          data = { ...data, ["orderSeries" + i]: "" };
          data = { ...data, ["orderNumber" + i]: "" };
          data = { ...data, ["orderIssuedDate" + i]: "" };
          data = { ...data, ["inspectionDateLifting" + i]: "" };
          data = { ...data, ["forInspectorLifting" + i]: "" };
          data = { ...data, ["inspectionDatePressure" + i]: "" };
          data = { ...data, ["forInspectorPressure" + i]: "" };
        }
        setValues({ ...values, ...data });
        setConfigs(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    configs.map(function (config, index) {
      if (config.rsvtiPersons.length === 1) {
        data = { ...data, ["rsvtiPerson" + index]: config.rsvtiPersons[0].uuid };
      }
      if (config.contactPersons.length === 1) {
        data = { ...data, ["contactPerson" + index]: config.contactPersons[0].uuid };
      }
    });
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      let newfield = {
        uuid: configs[i].uuid,
        urgent: values["urgent" + i],
        iscirOwnerUuid: values["rsvtiPerson" + i],
        contactUuid: values["contactPerson" + i],
        orderSeries: values["orderSeries" + i],
        orderNumber: values["orderNumber" + i],
        orderIssuedDate: values["orderIssuedDate" + i],
        inspectionDateLifting: values["inspectionDateLifting" + i],
        forInspectorLifting: values["forInspectorLifting" + i],
        inspectionDatePressure: values["inspectionDatePressure" + i],
        forInspectorPressure: values["forInspectorPressure" + i],
      };
      data = [...data, newfield];
    }
    axios
      .post(
        instructionsConfigSaveURL,
        {
          uuid: uuid,
          configs: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/equipmentdocuments/generatedocsview");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Listă evidență instalații ISCIR</p>
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 100}>
          <div class="rowdouble">
            <div class="column">
              <p class="pTitle">
                Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
              </p>
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <Checkbox
                key={index * 2 + 1}
                inputId={"urgent" + index}
                name={"urgent" + index}
                value="urgent"
                onChange={onChangeCheckBox}
                checked={values["urgent" + index] === "urgent"}
              />
              <label htmlFor={"urgent" + index} className="ml-2">
                &nbsp;&nbsp;Urgenţă
              </label>
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="text"
                name={"orderSeries" + index}
                label="Serie comandă"
                value={values["orderSeries" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="text"
                name={"orderNumber" + index}
                label="Număr comandă"
                value={values["orderNumber" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="date"
                name={"orderIssuedDate" + index}
                label="Dată emitere comandă"
                value={values["orderIssuedDate" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="text"
                name={"inspectionDateLifting" + index}
                label="Alege data inspecţie (instalaţii de ridicat)"
                value={values["inspectionDateLifting" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="text"
                name={"forInspectorLifting" + index}
                label="În atenţia: Inspector .................... (instalaţii de ridicat)"
                value={values["forInspectorLifting" + index]}
                onChange={onChange}
              />
            </div>
            <div class="rowdouble">
              <div class="column">
                <FormInput
                  key={index * 2 + 1}
                  type="text"
                  name={"inspectionDatePressure" + index}
                  label="Alege data inspecţie (instalaţii sub presiune)"
                  value={values["inspectionDatePressure" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div class="rowdouble">
              <div class="column">
                <FormInput
                  key={index * 2 + 1}
                  type="text"
                  name={"forInspectorPressure" + index}
                  label="În atenţia: Inspector .................... (instalaţii sub presiune)"
                  value={values["forInspectorPressure" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
            <div class="rowdouble">
              <div class="column">
                <FormSelect
                  key={index * 2}
                  name={"rsvtiPerson" + index}
                  label="Operator RSVTI"
                  value={values["rsvtiPerson" + index]}
                  ddOptions={config.rsvtiPersons}
                  onChange={onChange}
                />
              </div>
            </div>
            <div class="rowdouble">
              <div class="column">
                <FormSelect
                  key={index * 2}
                  name={"contactPerson" + index}
                  label="Responsabil punct de lucru"
                  value={values["contactPerson" + index]}
                  ddOptions={config.contactPersons}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Submit Buttons */}
      {processingDialog !== true ? (
        <div class="rowdouble">
          <div class="column">
            <button class="longButtonClassGray" onClick={handleGenerate}>
              Generare comandă CNCIR
            </button>
          </div>
        </div>
      ) : null}

      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
