import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons, doNothing } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListCompanyBranches() {
  const baseURL = BASE_URL + "company/listcompanybranches";
  const deleteURL = BASE_URL + "company/deletebranch";
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const downloadBranchesExcelURL = BASE_URL + "company/export/excel";
  const downloadIscirWorkersURL = BASE_URL + "worker/list/iscir/branches";
  const downloadIscirOperatorsURL = BASE_URL + "operator/list/iscir/branches";
  const downloadIscirEquipmentsURL = BASE_URL + "equipment/list/iscir/branches";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const { auth } = useAuth();

  const navigate = useNavigate();

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsWarning, setSelectedRowsWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewRowDialog, setViewRowDialog] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    townName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullAddress: { value: null, matchMode: FilterMatchMode.CONTAINS },
    countyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
          loadBranches(mainCompanySessionUuid);
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    loadBranches(e.target.value);
  };

  const loadBranches = (uuid) => {
    axios
      .post(
        baseURL,
        {
          uuid: uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading branches");
        console.log(error);
        alert("Punctele de lucru nu au putut fi incarcate");
      });
    // console.log("Submit");
  };

  // start company branches excel export
  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      // setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă puncte de lucru.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadBranchesExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
        link.click();
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Info)}
                alt="Detalii"
                title="Detalii"
                onClick={() => viewRow(rowData)}
              />
            </td>
            <td>&nbsp;&nbsp;</td>
            {null !== rowData.parentUuid ? (
              <td>
                <img
                  class="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.Delete)}
                  alt="Şterge"
                  title="Şterge"
                  onClick={() => deleteRow(rowData)}
                />
              </td>
            ) : null}
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  // hide selected rows warning dialog
  const closeRowDialog = (e) => {
    setViewRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting company");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const setWarningFalse = (e) => {
    setSelectedRowsWarning(false);
  };

  const goToEditCompanyBranch = async (e) => {
    if (null !== selectedRow.parentUuid) {
      sessionStorage.setItem("editCompanyBranchUuid", selectedRow.uuid);
      navigate("/companybranch/edit");
    } else {
      sessionStorage.setItem("editCompanyUuid", selectedRow.uuid);
      navigate("/company/edit");
    }
  };

  // start iscir personel
  const handleIscirWorkers = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi auxiliari.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirWorkersURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  // start iscir personel
  const handleIscirOperators = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirOperatorsURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  // start iscir personel
  const handleIscirEquipments = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă instalaţii ISCIR.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirEquipmentsURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Listă puncte de lucru</button>
      <div class="spaceDiv" />
      <img
        src={require("../../static/images/buttons/" + ICONS.CompanyBranchListDisabled)}
        alt="Listă puncte de lucru"
        title="Listă puncte de lucru"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAdd)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyBranchImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyBranchExport)}
        alt="Exportă puncte de lucru"
        title="Exportă puncte de lucru"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR- deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration)}
        alt="Listă evidenţă instalaţii ISCIR"
        title="Listă evidenţă instalaţii ISCIRi"
        onClick={handleIscirEquipments}
      />
      <div class="spaceDiv" />
      <div class="cardDropDown">
        <div class="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="name" filter filterPlaceholder="Caută" sortable header="Denumire firmă"></Column>
          <Column field="code" filter filterPlaceholder="Caută" sortable header="Cod punct de lucru"></Column>
          <Column field="townName" filter filterPlaceholder="Caută" sortable header="Localitate punct de lucru"></Column>
          <Column field="fullAddress" filter filterPlaceholder="Caută" sortable header="Adresă punct de lucru"></Column>
          <Column field="countyName" filter filterPlaceholder="Caută" sortable header="Judeţ/sector punct de lucru"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <div class="spaceDiv" />
      <img
        src={require("../../static/images/buttons/" + ICONS.CompanyBranchListDisabled)}
        alt="Listă puncte de lucru"
        title="Listă puncte de lucru"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyBranchAdd)} alt="Adaugă punct de lucru" title="Adaugă punct de lucru" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/companyBranch/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyBranchImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyBranchExport)}
        alt="Exportă puncte de lucru"
        title="Exportă puncte de lucru"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration)}
        alt="Listă evidenţă instalaţii ISCIR"
        title="Listă evidenţă instalaţii ISCIRi"
        onClick={handleIscirEquipments}
      />
      <div class="spaceDiv" />
      <Dialog
        visible={viewRowDialog}
        modal
        header={dialogHeaderElement("Detalii punct de lucru")}
        footer={dialogFooterContent("OK", closeRowDialog)}
        style={{ width: "50rem" }}
        onHide={() => setViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                <img
                  class="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.CompanyBranchEdit)}
                  alt="Modifică punct de lucru"
                  title="Modifică punct de lucru"
                  onClick={() => goToEditCompanyBranch()}
                />
              </td>
            </tr>
            <tr>
              <td class="tdleft">Denumire firmă:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.name : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Cod punct de lucru:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.code : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Adresă punct de lucru:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.fullAddress : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Telefon:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.phone : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">E-mail:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.email : ""}</td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", closeDeleteDialogAndDelete, "Nu", closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi punctul de lucru: {null != selectedRow ? selectedRow.code : ""} ?</p>
      </Dialog>
      <Dialog
        visible={selectedRowsWarning}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", setWarningFalse)}
        style={{ width: "50rem" }}
        onHide={() => setSelectedRowsWarning(false)}
      >
        <p className="m-0">Selectează un punct de lucru!</p>
      </Dialog>
    </div>
  );
}
