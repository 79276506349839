import React from "react";
import { Link } from "react-router-dom";

import { ICONS } from "../common/globals";

const Header = () => {
  return (
    <header>
      <div class="header">
        <img class="logo-at" src={require("../static/images/" + ICONS.LogoRsvti)} alt="Asisco Tehnic SRL" />

        <input class="side-menu" type="checkbox" id="side-menu" />
        <label class="hamb" for="side-menu">
          <span class="hamb-line"></span>
        </label>

        <nav class="nav">
          <ul class="menu">
            <li>
              <Link to={"/"}>Acasă</Link>{" "}
            </li>
            <li>
              <Link to={"/public/prices"}>Tarife</Link>
            </li>
            <li>
              <Link to={"/public/tutorials"}>Tutoriale</Link>
            </li>
            <li>
              <Link to={"/public/loginuser"}>Conectare</Link>
            </li>
            <li>
              <Link to={"/public/contact"}>Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
