// import dependencies
import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListWorkersOnHold() {
  const baseURL = BASE_URL + "worker/list/onhold";
  const deleteURL = BASE_URL + "worker/delete";
  const activateURL = BASE_URL + "worker/activate";
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const downloadWorkersExcelURL = BASE_URL + "workerdocuments/export/excel";
  // const downloadWorkersWordURL = BASE_URL + "workerdocuments/export/word";
  const downloadDocumentURL = BASE_URL + "worker/certificate/download";
  const downloadIscirWorkersURL = BASE_URL + "worker/list/iscir/selected";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  // authentication details
  const { auth } = useAuth();

  // for app navigation
  const navigate = useNavigate();

  // state objects
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsWarning, setSelectedRowsWarning] = useState(false);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewRowDialog, setViewRowDialog] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    identifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    workerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    certificateNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // hide selected rows warning dialog
  const setWarningFalse = (e) => {
    setSelectedRowsWarning(false);
  };

  // hide selected rows warning dialog
  const closeRowDialog = (e) => {
    setViewRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting worker certificate");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
          loadWorkers(mainCompanySessionUuid);
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load workers
  const loadWorkers = (uuid) => {
    axios
      .post(
        baseURL,
        {
          uuid: uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading workers");
        console.log(error);
        alert("Deservenţii auxiliari nu au putut fi încărcaţi!");
      });
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    loadWorkers(e.target.value);
  };

  // start worker certification workflow
  const handleGenerate = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    sessionStorage.setItem("workerUuids", JSON.stringify(uuids));
    navigate("/worker/generatedocstypeselection");
  };

  const activate = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        activateURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        navigate("/worker/list");
      })
      .catch((error) => {
        console.log(error);
        alert("Deservenţii auxiliari nu au putut fi activaţi!");
      });
  };

  // start worker excel export
  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă deservenţi auxiliari.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadWorkersExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
        link.click();
      });
  };

  // start worker word export
  const handleWordExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Lista deservenţi auxiliari.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadWorkersExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Info)}
                alt="Detalii"
                title="Detalii"
                onClick={() => viewRow(rowData)}
              />
            </td>
            <td>&nbsp;&nbsp;</td>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  const handleGetDocument = async (certificateDocumentUuid, certificateDocumentFileName, e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = certificateDocumentFileName;
    axios
      .post(
        downloadDocumentURL,
        {
          certificateDocumentUuid: certificateDocumentUuid,
          certificateDocumentFileName: certificateDocumentFileName,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], {}));
        link.click();
      });
  };

  const goToEditWorker = async (e) => {
    sessionStorage.setItem("editWorkerUuid", selectedRow.uuid);
    navigate("/worker/edit");
  };

  // start iscir personel
  const handleIscirWorkers = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi auxiliari.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirWorkersURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Listă deservenți auxiliari</button>
      <div class="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
        alt="Exportă deservenți auxiliari"
        title="Exportă deservenți auxiliari"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
        alt="Documente autorizare deservenți auxiliari"
        title="Documente autorizare deservenți auxiliari"
        onClick={handleGenerate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerActivate)}
        alt="Activează deservenți auxiliari"
        title="Activează deservenți auxiliari"
        onClick={activate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerOnHoldListDisabled)}
        alt="Listă deservenți auxiliari inactivi"
        title="Listă deservenți auxiliari inactivi"
      />
      <div class="spaceDiv" />
      {/* County and Town */}
      <div class="cardDropDown">
        <div class="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
      </div>
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="fullName" filter filterPlaceholder="Caută" sortable header="Nume angajat"></Column>
          <Column field="identifier" filter filterPlaceholder="Caută" sortable header="CNP"></Column>
          <Column field="companyCode" filter filterPlaceholder="Caută" sortable header="Cod punct de lucru angajat"></Column>
          <Column field="workerType" filter filterPlaceholder="Caută" sortable header="Tip deservent auxiliar"></Column>
          <Column field="certificateNumber" filter filterPlaceholder="Caută" sortable header="Număr adeverință"></Column>
          <Column field="certificateIssuedDate" sortable dataType="date" header="Dată eliberare adeverință"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <div class="spaceDiv" />
      <Link to={"/worker/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerList)} alt="Listă deservenți auxiliari" title="Listă deservenți auxiliari" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.WorkerAdd)} alt="Adaugă deservent auxiliar" title="Adaugă deservent auxiliar" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerImport)}
          alt="Importă deservenți auxiliari"
          title="Importă deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
        alt="Exportă deservenți auxiliari"
        title="Exportă deservenți auxiliari"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
        alt="Documente autorizare deservenți auxiliari"
        title="Documente autorizare deservenți auxiliari"
        onClick={handleGenerate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerActivate)}
        alt="Activează deservenți auxiliari"
        title="Activează deservenți auxiliari"
        onClick={activate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerOnHoldListDisabled)}
        alt="Listă deservenți auxiliari inactivi"
        title="Listă deservenți auxiliari inactivi"
      />
      <div class="spaceDiv" />
      <Dialog
        visible={viewRowDialog}
        modal
        header={dialogHeaderElement("Detalii deservent auxiliar")}
        footer={dialogFooterContent("OK", closeRowDialog)}
        style={{ width: "50rem" }}
        onHide={() => setViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                <img
                  class="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.WorkerEdit)}
                  alt="Modifică deservent auxiliar"
                  title="Modifică deservent auxiliar"
                  onClick={() => goToEditWorker()}
                />
              </td>
            </tr>
            <tr>
              <td class="tdleft">Nume angajat:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.fullName : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">CNP:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.identifier : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Cod punct de lucru angajat:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.companyCode : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Tip deservent auxiliar:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.workerType : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Număr adeverinţă:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.certificateNumber : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Dată eliberare adeverinţă:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.certificateIssuedDate : ""}</td>
            </tr>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                {null != selectedRow && selectedRow.certificateDocumentUuid !== null && selectedRow.certificateDocumentUuid !== "" ? (
                  <>
                    <img
                      class="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.WorkerExport)}
                      alt={"Descarca " + selectedRow.certificateDocumentFileName}
                      title={"Descarca " + selectedRow.certificateDocumentFileName}
                      onClick={(e) => handleGetDocument(selectedRow.certificateDocumentUuid, selectedRow.certificateDocumentFileName, e)}
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.certificateDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", closeDeleteDialogAndDelete, "Nu", closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi adeverinţa: {null != selectedRow ? selectedRow.certificateNumber : ""} ?</p>
      </Dialog>
      <Dialog
        visible={selectedRowsWarning}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", setWarningFalse)}
        style={{ width: "50rem" }}
        onHide={() => setSelectedRowsWarning(false)}
      >
        <p className="m-0">Selectează minim un deservent auxiliar!</p>
      </Dialog>
    </div>
  );
}
