// import dependencies
import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListOperators() {
  const baseURL = BASE_URL + "operator/list";
  const deleteURL = BASE_URL + "operator/delete";
  const deactivateURL = BASE_URL + "operator/inactivate";
  const companyListURL = BASE_URL + "company/listsimplecompanies";
  const downloadOperatorsExcelURL = BASE_URL + "operatordocuments/export/excel";
  const operatorCreateSessionURL = BASE_URL + "operatordocuments/createsession";
  const prescriptionsURL = BASE_URL + "structure/operator/prescriptions";
  const operatorTypesURL = BASE_URL + "structure/operator/types/exact";
  const downloadRegistrationDocumentURL = BASE_URL + "operator/registration/download";
  const downloadPermitDocumentURL = BASE_URL + "operator/permit/download";
  const downloadIscirOperatorsURL = BASE_URL + "operator/list/iscir/selected";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsWarning, setSelectedRowsWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewRowDialog, setViewRowDialog] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    identifier: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    operatorType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    registrationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const { auth } = useAuth();

  const navigate = useNavigate();

  const [values, setValues] = useState({
    companyUuid: "",
    prescriptionUuid: "",
    operatorTypeUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
    {
      id: 1,
      name: "prescriptionUuid",
      type: "selectClass",
      placeholder: "Prescriptie",
      label: "Prescriptie",
    },
    {
      id: 2,
      name: "operatorTypeUuid",
      type: "selectClass",
      placeholder: "Tip deservent",
      label: "Tip deservent",
    },
  ];

  // hide selected rows warning dialog
  const setWarningFalse = (e) => {
    setSelectedRowsWarning(false);
  };

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [operatorTypes, setOperatorTypes] = useState([]);

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
          loadOperators(mainCompanySessionUuid, "");
        }
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load prescriptions
  useEffect(() => {
    axios
      .post(
        prescriptionsURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setPrescriptions(response.data);
      })
      .catch((error) => {
        console.log("Error loading prescriptions");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectedRows([]);
    loadOperators(e.target.value, values.operatorTypeUuid);
  };

  const onChangePrescription = (e) => {
    console.log("onChangePrescriptions");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      operatorTypeUuid: "",
    });
    setSelectedRows([]);
    setOperatorTypes([]);
    axios
      .post(
        operatorTypesURL,
        { uuid: e.target.value },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setOperatorTypes(response.data);
      })
      .catch((error) => {
        console.log("Error loading prescriptions");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onChangeOperatorType = (e) => {
    console.log("onChangeOperatorType");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    setSelectedRows([]);
    loadOperators(values.companyUuid, values.prescriptionUuid, e.target.value);
  };

  const loadOperators = (compUuid, prescUuid, operUuid) => {
    axios
      .post(
        baseURL,
        {
          uuid: compUuid,
          prescriptionUuid: prescUuid,
          operatorUuid: operUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading operator");
        console.log(error);
        alert("Deserventii nu au putut fi incarcati");
      });
    // console.log("Submit");
  };

  // process start operator recertification workflow
  const handleGenerate = async (e) => {
    e.preventDefault();
    if (values.operatorTypeUuid === null || values.operatorTypeUuid.length === 0) {
      alert("Selecteaza tipul deserventului!");
      return;
    }
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        operatorCreateSessionURL,
        {
          uuids: uuids,
          prescriptionUuid: values.prescriptionUuid,
          typeUuid: values.operatorTypeUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("operatorSessionUuid", response.data);
        navigate("/operator/generatedocsinstructions");
      })
      .catch((error) => {
        console.log(error);
        alert("Autorizarea deserventilor nu a putut fi pornita!");
      });
  };

  const inactivate = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        deactivateURL,
        {
          uuids: uuids,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        navigate("/operator/onholdlist");
      })
      .catch((error) => {
        console.log(error);
        alert("Deserventii nu au putut fi dezactivati!");
      });
  };

  // start operator excel export
  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă deservenţi.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadOperatorsExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
        link.click();
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Info)}
                alt="Detalii"
                title="Detalii"
                onClick={() => viewRow(rowData)}
              />
            </td>
            <td>&nbsp;&nbsp;</td>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  // hide selected rows warning dialog
  const closeRowDialog = (e) => {
    setViewRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting worker certificate");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  const handleGetDocument = async (documentUuid, documentFileName, type, e) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = documentFileName;
    let URL_D = downloadRegistrationDocumentURL;
    if (type === 2) {
      URL_D = downloadPermitDocumentURL;
    }
    axios
      .post(
        URL_D,
        {
          uuid: documentUuid,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], {}));
        link.click();
      });
  };

  const goToEditOperator = async (e) => {
    sessionStorage.setItem("editOperatorUuid", selectedRow.uuid);
    navigate("/operator/edit");
  };

  // start iscir personel
  const handleIscirOperators = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirOperatorsURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Listă deservenți</button>
      <div class="spaceDiv" />
      <img src={require("../../static/images/buttons/" + ICONS.OperatorListDisabled)} alt="Listă deservenți" title="Listă deservenți" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorAdd)} alt="Adaugă deservent" title="Adaugă deservent" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
        alt="Exportă deservenți"
        title="Exportă deservenți"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
        alt="Documente reautorizare deservenți"
        title="Documente reautorizare deservenți"
        onClick={handleGenerate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorInactivate)}
        alt="Dezactivează deservenți"
        title="Dezactivează deservenți"
        onClick={inactivate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/onholdlist"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.OperatorOnHoldList)}
          alt="Listă deservenți inactivi"
          title="Listă deservenți inactivi"
        />
      </Link>
      <div class="spaceDiv" />
      {/* County and Town */}
      <div class="cardDropDown">
        <div class="column">
          <FormSelect
            key={inputs[0].id}
            name={inputs[0].name}
            label={inputs[0].label}
            value={values[inputs[0].name]}
            ddOptions={ddOptionsCompany}
            onChange={onChange}
          />
        </div>
        <div class="column">
          <FormSelect
            key={inputs[1].id}
            name={inputs[1].name}
            label={inputs[1].label}
            value={values[inputs[1].name]}
            ddOptions={prescriptions}
            onChange={onChangePrescription}
          />
        </div>
        <div class="column">
          <FormSelect
            key={inputs[2].id}
            name={inputs[2].name}
            label={inputs[2].label}
            value={values[inputs[2].name]}
            ddOptions={operatorTypes}
            onChange={onChangeOperatorType}
          />
        </div>
      </div>
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="fullName" filter filterPlaceholder="Caută" sortable header="Nume angajat"></Column>
          <Column field="identifier" filter filterPlaceholder="Caută" sortable header="CNP"></Column>
          <Column field="code" filter filterPlaceholder="Caută" sortable header="Cod punct de lucru"></Column>
          <Column field="operatorType" filter filterPlaceholder="Caută" sortable header="Tip deservent"></Column>
          <Column field="registrationNumber" filter filterPlaceholder="Caută" sortable header="Număr autorizație"></Column>
          <Column field="permitVisaDate" sortable dataType="date" header="Dată vizare talon"></Column>
          <Column field="permitExpirationDate" sortable dataType="date" header="Dată expirare talon"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <img src={require("../../static/images/buttons/" + ICONS.OperatorListDisabled)} alt="Listă deservenți" title="Listă deservenți" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorAdd)} alt="Adaugă deservent" title="Adaugă deservent" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
        alt="Exportă deservenți"
        title="Exportă deservenți"
        onClick={handleExcelExport}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
        alt="Documente reautorizare deservenți"
        title="Documente reautorizare deservenți"
        onClick={handleGenerate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorInactivate)}
        alt="Dezactivează deservenți"
        title="Dezactivează deservenți"
        onClick={inactivate}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/onholdlist"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.OperatorOnHoldList)}
          alt="Listă deservenți inactivi"
          title="Listă deservenți inactivi"
        />
      </Link>
      <div class="spaceDiv" />
      <Dialog
        visible={viewRowDialog}
        modal
        header={dialogHeaderElement("Detalii deservent")}
        footer={dialogFooterContent("OK", closeRowDialog)}
        style={{ width: "50rem" }}
        onHide={() => setViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                <img
                  class="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.OperatorEdit)}
                  alt="Modifică deservent"
                  title="Modifică deservent"
                  onClick={() => goToEditOperator()}
                />
              </td>
            </tr>
            <tr>
              <td class="tdleft">Nume angajat:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.fullName : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">CNP:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.identifier : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Cod punct de lucru:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.code : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Tip deservent:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.operatorType : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Număr autorizație:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.registrationNumber : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Dată vizare talon:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.permitVisaDate : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Dată expirare talon:</td>
              <td class="tdrigth"> {null != selectedRow ? selectedRow.permitExpirationDate : ""}</td>
            </tr>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                {null != selectedRow && selectedRow.registrationDocumentUuid !== null && selectedRow.registrationDocumentUuid !== "" ? (
                  <>
                    <img
                      class="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
                      alt={"Descarca " + selectedRow.registrationDocumentFileName}
                      title={"Descarca " + selectedRow.registrationDocumentFileName}
                      onClick={(e) => handleGetDocument(selectedRow.registrationDocumentUuid, selectedRow.registrationDocumentFileName, 1, e)}
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.registrationDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                {null != selectedRow && selectedRow.permitDocumentUuid !== null && selectedRow.permitDocumentUuid !== "" ? (
                  <>
                    <img
                      class="imgtoolbar"
                      src={require("../../static/images/buttons/" + ICONS.OperatorExport)}
                      alt={"Descarca " + selectedRow.permitDocumentFileName}
                      title={"Descarca " + selectedRow.permitDocumentFileName}
                      onClick={(e) => handleGetDocument(selectedRow.permitDocumentUuid, selectedRow.permitDocumentFileName, 2, e)}
                    />
                    &nbsp;&nbsp;{"Descarca " + selectedRow.permitDocumentFileName}
                  </>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", closeDeleteDialogAndDelete, "Nu", closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi autorizaţia: {null != selectedRow ? selectedRow.registrationNumber : ""} ?</p>
      </Dialog>
      <Dialog
        visible={selectedRowsWarning}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", setWarningFalse)}
        style={{ width: "50rem" }}
        onHide={() => setSelectedRowsWarning(false)}
      >
        <p className="m-0">Selectează minim un deservent!</p>
      </Dialog>
    </div>
  );
}
