import Sidebar from "../components/Sidebar";
import TextField from "@mui/material/TextField";

export default function ApplyCustomCss() {
  return (
    <div className="ApplyCustomCss">
      <Sidebar />
      <TextField
        id="outlined-basic"
        label="Outlined"
        variant="outlined"
        sx={{
          height: "150px",
          backgroundColor: "yellowgreen",
          input: { color: "red" },
        }}
      />
    </div>
  );
}
