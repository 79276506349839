import "../../static/css/pageLayout.css";

import axios from "axios";
import { RadioButton } from "primereact/radiobutton";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, dialogFooterContent, ICONS } from "../../common/globals";
import FormInput from "../../components/FormInput";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateDocsCertificates() {
  const generateCertificatesURL = BASE_URL + "workerdocuments/generate/certificates";
  const typesURL = BASE_URL + "workerdocuments/get/session/type";
  const workersURL = BASE_URL + "workerdocuments/get/workers";

  const uuid = sessionStorage.getItem("workerSessionUuid");
  const companyUuid = sessionStorage.getItem("companyUuid");

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [values, setValues] = useState({
    pvResultNumber: "",
    pvResultIssuedDate: "",
    pvAuthoriseNumber: "",
    pvAuthoriseIssuedDate: "",
    certificateSeries: "",
    certificateNumber: "",
    certificateIssuedDate: "",
  });

  const [types, setTypes] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [results, setResults] = useState([]);

  const inputs = [
    {
      id: 0,
      name: "pvResultNumber",
      type: "text",
      placeholder: "Alege număr PV rezultate testare - format: PVRT001",
      label: "Alege număr PV rezultate testare",
    },
    {
      id: 1,
      name: "pvResultIssuedDate",
      type: "date",
      placeholder: "Alege data emitere PV rezultate testare",
      label: "Alege data emitere PV rezultate testare",
    },
    {
      id: 2,
      name: "pvAuthoriseNumber",
      type: "text",
      placeholder: "Alege număr PV autorizare - format: PVRT002",
      label: "Alege număr PV autorizare",
    },
    {
      id: 3,
      name: "pvAuthoriseIssuedDate",
      type: "date",
      placeholder: "Alege data emitere PV autorizare",
      label: "Alege data emitere PV autorizare",
    },
    {
      id: 5,
      name: "certificateSeries",
      type: "text",
      placeholder: "Alege serie adeverință",
      label: "Alege serie adeverință",
    },
    {
      id: 5,
      name: "certificateNumber",
      type: "text",
      placeholder: "Alege număr adeverință - format: 001",
      label: "Alege număr adeverință",
    },
    {
      id: 6,
      name: "certificateIssuedDate",
      type: "date",
      placeholder: "Alege data emitere adeverință",
      label: "Alege data emitere adeverință",
    },
  ];

  useEffect(() => {
    axios
      .post(
        typesURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setTypes(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        workersURL,
        {
          uuid: uuid,
          companyUuid: companyUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.length; i++) {
          data = { ...data, [response.data[i].uuid + "result"]: "Admis" };
        }
        setResults({ ...results, ...data });
        setWorkers(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeRadio = (e) => {
    setResults({ ...results, [e.target.name]: e.target.value });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    let data = [];
    for (let i = 0; i < workers.length; i++) {
      let newfield = { uuid: workers[i].uuid, result: results[workers[i].uuid + "result"] };
      data = [...data, newfield];
    }
    axios
      .post(
        generateCertificatesURL,
        {
          uuid: uuid,
          companyUuid: companyUuid,
          pvResultNumber: values.pvResultNumber,
          pvResultIssuedDate: values.pvResultIssuedDate,
          pvAuthoriseNumber: values.pvAuthoriseNumber,
          pvAuthoriseIssuedDate: values.pvAuthoriseIssuedDate,
          certificateSeries: values.certificateSeries,
          certificateNumber: values.certificateNumber,
          certificateIssuedDate: values.certificateIssuedDate,
          results: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/worker/generatedocsstatus");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const handleViewDocuments = async (sessionUuid, e) => {
    e.preventDefault();
    sessionStorage.setItem("sessionUuid", sessionUuid);
    navigate("/worker/generatedocsview");
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <div class="rowdouble">
        <div class="column">
          <table class="tableWorkers">
            <tbody>
              {workers.map((worker, index) => (
                <tr>
                  <td class="columnWorkers">{worker.lastName}</td>
                  <td class="columnWorkers">{worker.firstName}</td>
                  <td class="columnWorkers">{worker.identifier}</td>
                  <td class="columnWorkers">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId={worker.uuid + "A"}
                        name={worker.uuid + "result"}
                        value="Admis"
                        onChange={onChangeRadio}
                        checked={results[worker.uuid + "result"] === "Admis"}
                      />
                      <label htmlFor={worker.uuid + "A"} className="flex align-items-center">
                        Admis
                      </label>
                    </div>
                  </td>
                  <td class="columnWorkers">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId={worker.uuid + "R"}
                        name={worker.uuid + "result"}
                        value="Respins"
                        onChange={onChangeRadio}
                        checked={results[worker.uuid + "result"] === "Respins"}
                      />
                      <label htmlFor={worker.uuid + "R"} className="flex align-items-center">
                        Respins
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Proces verbal rezultate testare {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[0].id} {...inputs[0]} value={values[inputs[0].name]} onChange={onChange} />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[1].id} {...inputs[1]} value={values[inputs[1].name]} onChange={onChange} />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Proces verbal autorizare {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[2].id} {...inputs[2]} value={values[inputs[2].name]} onChange={onChange} />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[3].id} {...inputs[3]} value={values[inputs[3].name]} onChange={onChange} />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">
            Adeverință {types.workerType} - {types.equipmentClassification}
          </p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[4].id} {...inputs[4]} value={values[inputs[4].name]} onChange={onChange} />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[5].id} {...inputs[5]} value={values[inputs[5].name]} onChange={onChange} />
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormInput key={inputs[6].id} {...inputs[6]} value={values[inputs[6].name]} onChange={onChange} />
        </div>
      </div>

      {processingDialog !== true ? (
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
              alt={"Generare documente autorizare " + types.workerType + " - " + types.equipmentClassification}
              title={"Generare documente autorizare " + types.workerType + " - " + types.equipmentClassification}
              onClick={handleGenerate}
            />
          </div>
        </div>
      ) : null}
      <div class="rowdoublecentered">
        <div class="columnbutton">
          <img
            class="imgtoolbar"
            src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
            alt="Vezi documentele generate până la acest pas"
            title="Vezi documentele generate până la acest pas"
            onClick={(e) => handleViewDocuments(uuid, e)}
          />
        </div>
      </div>

      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
