import "../static/css/priceheader.css";
import "../static/css/price.css";
import "../static/css/pricefooter.css";

import "primeflex/primeflex.css";

import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function Prices() {
  return (
    <div className="Prices">
      <Header />

      <main>
        <div className="surface-0">
          <br />
          <br />
          <div>
            <img alt="" className="img-all" src={require("../static/images/1920x540/bckg_1920x540_002.jpg")} />
          </div>
          <br />

          <div className="text-900 font-bold text-6xl mb-4 text-center">Prețuri pachete de bază</div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Aceste prețuri sunt valabile pentru utilizatorii care folosesc doar anumite prescripții tehnice ISCIR <br />
            (3 prescripții tehnice ISCIR pentru instalațiile de ridicat și 3 prescripții tehnice ISCIR pentru instalațiile sub presiune).
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Gratuit</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">0 lei / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">0 lei / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                      </div>
                      <span>puncte de lucru: 1</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                      </div>
                      <span>echipamente: 5</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                      </div>
                      <span>personal ISCIR: 5</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <div class="ft-icon left">
                        <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                      </div>
                      <span>spațiu: maxim 50 MB </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Bază 150</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">150 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">15 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 2.5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Bază 250</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">250 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">25 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
        </div>

        <div className="surface-0">
          <div className="text-900 font-bold text-6xl mb-4 text-center">Prețuri pachete complete</div>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Aceste prețuri sunt valabile pentru utilizatorii care folosesc toate prescripțiile tehnice ISCIR <br />
            (toate prescripțiile tehnice ISCIR pentru instalațiile de ridicat și toate prescripțiile tehnice ISCIR pentru instalațiile sub presiune).
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Personalizat</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />

                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">... euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: conform cerințe client</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: conform cerințe client</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: conform cerințe client</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: conform cerințe client </span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 h-full flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Complet 200</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">200 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">20 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: 100</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 2.5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div className="shadow-2 p-3 flex flex-column" style={{ borderRadius: "6px" }}>
                  <div className="text-900 font-medium text-xl mb-2">Complet 300</div>
                  <div className="text-600">Descriere pachet</div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">300 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">an</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">30 euro + TVA / </span>
                    <span className="ml-2 font-medium text-600">lună</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/company_24x24.png")} />
                        </div>
                      </i>
                      <span>puncte de lucru: 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/equipment_24x24.png")} />
                        </div>
                      </i>
                      <span>echipamente: 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/worker_24x24.png")} />
                        </div>
                      </i>
                      <span>personal ISCIR: 200</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2">
                        <div class="ft-icon left">
                          <img alt="" src={require("../static/images/24x24/data_24x24.png")} />
                        </div>
                      </i>
                      <span>spațiu: maxim 5 GB</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="btn-lnk-blue">
                    <Link to={"/public/prices"}>
                      <span className=" p-3 w-full mt-auto txt-ctr">Aplică acum</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div>
        <br />
        <br />
      </div>

      <Footer />
    </div>
  );
}
