// import dependencies
import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContentTwoButtons, warningHeaderElement } from "../../common/globals";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListOperatorSessions() {
  const baseURL = BASE_URL + "operator/session/list";
  const deleteURL = BASE_URL + "operator/session/delete";

  // state objects
  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowWarning, setSelectedRowWarning] = useState(false);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  // authentication details
  const { auth } = useAuth();

  // for app navigation
  const navigate = useNavigate();

  // footer of the selected rows warning dialog
  const footerContent = (
    <div>
      <Button label="Ok" icon="pi pi-check" onClick={() => setSelectedRowWarning(false)} autoFocus />
    </div>
  );

  // load operator generated documents sessions
  useEffect(() => {
    axios
      .post(
        baseURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading operator sessions");
        console.log(error);
        alert("Reautorizările deservenţilor nu au putut fi încărcate!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // view operator generated documents status for selected session
  const handleGenerate = async (e) => {
    e.preventDefault();
    if (selectedRow === null || selectedRow.length === 0) {
      setSelectedRowWarning(true);
      return;
    }
    sessionStorage.setItem("operatorSessionUuid", selectedRow.sessionUuid);
    navigate("/operator/generatedocsstatus");
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting worker certificate");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Reautorizări deservenți</button>
      <div class="spaceDiv" />
      <Link to={"/company/sessions"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)} alt="Documente firmă" title="Documente firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocumentsDisabled)}
        alt="Documente reautorizări deservenți"
        title="Documente reautorizări deservenți"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
          alt="Documente certificări deservenți auxiliari"
          title="Documente certificări deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingGenerateDocuments)}
          alt="Documente echipamente"
          title="Documente echipamente"
        />
      </Link>
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRow}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="sessionUuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
          <Column field="type" sortable header="Tip deservent"></Column>
          <Column field="date" sortable dataType="date" header="Data"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <button class="longButtonClassGray" onClick={handleGenerate}>
        Vezi documente reautorizare deservenți
      </button>
      <Dialog
        visible={selectedRowWarning}
        modal
        header={warningHeaderElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => setSelectedRowWarning(false)}
      >
        <p className="m-0">Selectează o reautorizare de deservenţi!</p>
      </Dialog>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", closeDeleteDialogAndDelete, "Nu", closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">
          Doreşti să ştergi documentele pentru: {null != selectedRow ? selectedRow.type : ""} din data: {null != selectedRow ? selectedRow.date : ""}{" "}
          ?
        </p>
      </Dialog>
    </div>
  );
}
