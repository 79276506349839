import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function WorkerGenerateDocsWorkerTypeSelection() {
  const workerCreateSessionURL = BASE_URL + "workerdocuments/createsession";
  const workerTypesURL = BASE_URL + "structure/worker/types";
  const structureChildrenURL = BASE_URL + "structure/worker/children";

  const [workerTypes, setWorkerTypes] = useState([]);
  const [equipmentClassifications, setEquipmentClassifications] = useState([]);
  const [equipmentGroups, setEquipmentGroups] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);

  const navigate = useNavigate();

  const { auth } = useAuth();

  const uuids = JSON.parse(sessionStorage.getItem("workerUuids"));

  const [values, setValues] = useState({
    workerType: "",
    equipmentClassification: "",
    equipmentGroup: "",
    equipmentType: "",
    maxMass: "",
  });

  const inputs = [
    {
      id: 0,
      name: "workerType",
      type: "select",
      placeholder: "Tip deservent auxiliar",
      label: "Tip deservent auxiliar",
    },
    {
      id: 1,
      name: "equipmentClassification",
      type: "select",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
    },
    {
      id: 2,
      name: "equipmentGroup",
      type: "select",
      placeholder: "Grupă echipament",
      label: "Grupă echipament",
    },
    {
      id: 3,
      name: "equipmentType",
      type: "select",
      placeholder: "Tip echipament",
      label: "Tip echipament",
    },
    {
      id: 4,
      name: "maxMass",
      type: "text",
      placeholder: "Sarcina maximă",
      label: "Sarcina maximă",
    },
  ];

  useEffect(() => {
    axios
      .post(
        workerTypesURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setWorkerTypes(response.data);
      })
      .catch((error) => {
        console.log(error);
        alert("Tipurile de deservenţi auxiliari nu au putut fi citite!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeWorkerType = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassification: "", equipmentGroup: "", equipmentType: "" });
    setEquipmentClassifications([]);
    setEquipmentGroups([]);
    setEquipmentTypes([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setEquipmentClassifications(response.data.values);
      })
      .catch((error) => {
        console.log(error);
        alert("Clasificările echipamentelor nu au putut fi citite!");
      });
  };

  const onChangeEquipmentClassification = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroup: "", equipmentType: "" });
    setEquipmentGroups([]);
    setEquipmentTypes([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (response.data.type === "equipment group") {
          setEquipmentGroups(response.data.values);
        } else {
          setEquipmentTypes(response.data.values);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Configuraţia echipamentului nu a putut fi citită!");
      });
  };

  const onChangeEquipmentGroup = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentType: "" });
    setEquipmentTypes([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setEquipmentTypes(response.data.values);
      })
      .catch((error) => {
        console.log(error);
        alert("Tipurile de echipamente nu au putut fi citite!");
      });
  };

  const onChangeEquipmentType = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    axios
      .post(
        workerCreateSessionURL,
        {
          uuids: uuids,
          typeUuid: values.workerType,
          equipmentClassificationUuid: values.equipmentClassification,
          equipmentGroupUuid: values.equipmentGroup,
          equipmentTypeUuid: values.equipmentType,
          maxMass: values.maxMass,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        sessionStorage.setItem("workerSessionUuid", response.data);
        navigate("/worker/generatedocsinstructions");
      })
      .catch((error) => {
        console.log(error);
        alert("Certificarea deservenţilor auxiliari nu a putut fi pornită!");
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <form class="formArea">
        <div class="rowdouble">
          <div class="column">
            <FormSelect
              key={inputs[0].id}
              name={inputs[0].name}
              label={inputs[0].label}
              value={values[inputs[0].name]}
              ddOptions={workerTypes}
              onChange={onChangeWorkerType}
            />
          </div>
        </div>
        <div class="rowdouble">
          <div class="column">
            <FormSelect
              key={inputs[1].id}
              name={inputs[1].name}
              label={inputs[1].label}
              value={values[inputs[1].name]}
              ddOptions={equipmentClassifications}
              onChange={onChangeEquipmentClassification}
            />
          </div>
        </div>
        <div class="rowdouble">
          <div class="column">
            <FormSelect
              key={inputs[2].id}
              name={inputs[2].name}
              label={inputs[2].label}
              value={values[inputs[2].name]}
              ddOptions={equipmentGroups}
              onChange={onChangeEquipmentGroup}
            />
          </div>
        </div>
        <div class="rowdouble">
          <div class="column">
            <FormSelect
              key={inputs[3].id}
              name={inputs[3].name}
              label={inputs[3].label}
              value={values[inputs[3].name]}
              ddOptions={equipmentTypes}
              onChange={onChangeEquipmentType}
            />
          </div>
        </div>
        <div class="rowdouble">
          <div class="column">
            <FormInput key={inputs[4].id} {...inputs[4]} value={values[inputs[4].name]} onChange={onChange} />
          </div>
        </div>
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.WorkerGenerateWordDocuments)}
              alt="Generare documentație instruire"
              title="Generare documentație instruire"
              onClick={handleGenerate}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
