import axios from "axios";
import React, { useState } from "react";

import { BASE_URL } from "../common/globals";
import FormInput from "../components/FormInput";
import Sidebar from "../components/Sidebar";
import useAuth from "../hooks/useAuth";

export default function AddIscir() {
  const baseURL = BASE_URL + "admin/add";

  const { auth } = useAuth();

  const [values, setValues] = useState({
    name: "",
    details: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Nume",
      label: "Nume Iscir",
    },
    {
      id: 2,
      name: "clientUuid",
      type: "text",
      placeholder: "uuid client",
      label: "UUID Client",
    },
    {
      id: 3,
      name: "registrationNumber",
      type: "text",
      placeholder: "numar inregistrare",
      label: "Numar inregistrare",
    },
    {
      id: 4,
      name: "fiscalCode",
      type: "text",
      placeholder: "cod fiscal",
      label: "Cod fiscal",
    },
    {
      id: 5,
      name: "addressUuid",
      type: "text",
      placeholder: "uuid adresa",
      label: "UUID Adresa",
    },
    {
      id: 6,
      name: "phone",
      type: "text",
      placeholder: "telefon",
      label: "Telefon",
    },
    {
      id: 7,
      name: "fax",
      type: "text",
      placeholder: "fax",
      label: "Fax",
    },
    {
      id: 8,
      name: "email",
      type: "text",
      placeholder: "email",
      label: "Email",
    },
    {
      id: 9,
      name: "bankAccount",
      type: "text",
      placeholder: "cont bancar",
      label: "Cont bancar",
    },
    {
      id: 10,
      name: "bankName",
      type: "text",
      placeholder: "banca",
      label: "Banca",
    },
    {
      id: 11,
      name: "bankLocation",
      type: "text",
      placeholder: "sucursala banca",
      label: "Sucursala banca",
    },
    {
      id: 12,
      name: "contactPerson",
      type: "text",
      placeholder: "nume",
      label: "Persoana de contact",
    },
    {
      id: 13,
      name: "contactPersonRole",
      type: "text",
      placeholder: "rol",
      label: "Rol persoana de contact",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        baseURL,
        {
          name: values.name,
          clientUuid: values.clientUuid,
          registrationNumber: values.registrationNumber,
          fiscalCode: values.fiscalCode,
          addressUuid: values.addressUuid,
          phone: values.phone,
          fax: values.fax,
          email: values.email,
          bankAccount: values.bankAccount,
          bankName: values.bankName,
          bankLocation: values.bankLocation,
          contactPerson: values.contactPerson,
          contactPersonRole: values.contactPersonRole,
          userUUID: "892ee26b-d314-11ed-bda1-902b346c1788",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        console.log("Company Added");
        console.log(res.data);
        alert("Firma adaugata");
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);
  console.log("render");

  return (
    <div className="AddIscir">
      <Sidebar />
      <form onSubmit={handleSubmit}>
        <h1>Adauga Iscir</h1>
        {inputs.map((input) => (
          <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
        ))}
        <button>Submit</button>
      </form>
    </div>
  );
}
