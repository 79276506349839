import "../../static/css/pageLayout.css";

import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, dialogFooterContent } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import ProcessImportErrors from "../../components/ProcessImportErrors";

export default function ImportEquipments() {
  const importURL = BASE_URL + "import/equipment";
  const companyListURL = BASE_URL + "company/listsimplecompanies";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [fileL, setFileL] = useState(null);
  const [fileP, setFileP] = useState(null);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);

  // authentication details
  const { auth } = useAuth();

  const [values, setValues] = useState({
    companyUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Companie",
      label: "Companie",
    },
  ];

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
        if (null !== mainCompanySessionUuid) {
          setValues({ ...values, companyUuid: mainCompanySessionUuid });
        }
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    setValidationErrors(null);
    const formData = new FormData();
    if (fileL !== null) {
      formData.append("files", fileL);
    }
    if (fileP !== null) {
      formData.append("files", fileP);
    }
    formData.append("companyUuid", values.companyUuid);
    axios
      .post(importURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        setProcessingDialog(false);
        if (null !== res.data && res.data.statusType !== "OK") {
          setValidationErrors(res.data);
        } else {
          setProcessedDialog(true);
        }
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button class="buttonClassGreen">Importă echipamente</button>
      <div class="spaceDiv" />
      <Link to={"/equipment/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingList)} alt="Listă echipamente" title="Listă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addpressure"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
          alt="Adaugă echipament sub presiune"
          title="Adaugă echipament sub presiune"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImportDisabled)}
        alt="Importă echipamente"
        title="Importă echipamente"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExportDisabled)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      <form class="formArea">
        <div class="row">
          <div class="column">
            <FormSelect
              key={inputs[0].id}
              name={inputs[0].name}
              label={inputs[0].label}
              value={values[inputs[0].name]}
              ddOptions={ddOptionsCompany}
              onChange={onChange}
            />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>
        {/* File */}
        <div class="row">
          <div class="column">
            Instalaţii de ridicat
            <br />
            <input type="file" onChange={(e) => setFileL(e.target.files[0])} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {/* File */}
        <div class="row">
          <div class="column">
            Instalaţii sub presiune
            <br />
            <input type="file" onChange={(e) => setFileP(e.target.files[0])} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {/* Submit Buttons */}
        {processingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingSaveAll)}
                alt="Importă"
                title="Importă"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
        {null !== validationErrors && null !== validationErrors.statusType && validationErrors.statusType !== "OK" ? (
          <ProcessImportErrors validationErrors={validationErrors} />
        ) : null}
      </form>
      <Link to={"/equipment/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingList)} alt="Listă echipamente" title="Listă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addpressure"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAdd)}
          alt="Adaugă echipament sub presiune"
          title="Adaugă echipament sub presiune"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingImportDisabled)}
        alt="Importă echipamente"
        title="Importă echipamente"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExportDisabled)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      <div class="spaceDiv" />
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
