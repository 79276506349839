import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";

import { BASE_URL } from "../../common/globals";
import Sidebar from "../../components/Sidebar";

export default function ListAddresses() {
  const baseURL = BASE_URL + "address/list";

  const [post, setPost] = useState(null);

  const columns = [
    {
      field: "uuid",
      headerName: "UUID",
      width: 300,
      editable: true,
    },
    {
      field: "addressDetails1",
      headerName: "Adresa 1",
      width: 150,
      editable: false,
    },
    {
      field: "addressDetails2",
      headerName: "Adresa 1",
      width: 150,
      editable: true,
    },
    {
      field: "townUuid",
      headerName: "UUID Oraș",
      width: 300,
      editable: false,
    },
    {
      field: "townName",
      headerName: "Oraș",
      width: 150,
      editable: false,
    },
    {
      field: "countyUuid",
      headerName: "UUID Județ",
      width: 300,
      editable: false,
    },
    {
      field: "countyName",
      headerName: "Județ",
      width: 150,
      editable: false,
    },
    {
      field: "postalCode",
      headerName: "Cod poștal",
      width: 150,
      editable: false,
    },
  ];

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!post)
    return (
      <div className="listaddresses">
        <Sidebar />
        <h1>No Post</h1>
      </div>
    );

  return (
    <div className="listaddresses">
      <Sidebar />
      <h1>Lista adrese</h1>
      <Box sx={{ height: 500, width: "90%" }}>
        <DataGrid
          getRowId={(row) => row.uuid}
          rows={post}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
}
