import axios from "axios";
import { useState } from "react";

import { BASE_URL } from "../../common/globals";
import FormInput from "../../components/FormInput";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function AddPerson() {
  const baseURL = BASE_URL + "person/add";

  const { auth } = useAuth();

  const [values, setValues] = useState({
    name: "",
    details: "",
  });

  const inputs = [
    {
      id: 1,
      name: "lastName",
      type: "text",
      placeholder: "nume de familie",
      label: "Nume de familie",
    },
    {
      id: 2,
      name: "firstName",
      type: "text",
      placeholder: "prenume",
      label: "Prenume",
    },
    {
      id: 3,
      name: "middleName",
      type: "text",
      placeholder: "nume mijlociu",
      label: "Nume mijlociu",
    },
    {
      id: 4,
      name: "identifier",
      type: "text",
      placeholder: "cnp",
      label: "CNP",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        baseURL,
        {
          lastName: values.lastName,
          firstName: values.firstName,
          middleName: values.middleName,
          identifier: values.identifier,
          userUUID: "892ee26b-d314-11ed-bda1-902b346c1788",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        console.log("Person Added");
        console.log(res.data);
        alert("Persoana adaugata");
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);
  console.log("render");

  return (
    <div className="AddPerson">
      <Sidebar />
      <form onSubmit={handleSubmit}>
        <h1>Adaugă persoană</h1>
        {inputs.map((input) => (
          <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
        ))}
        <button>Submit</button>
      </form>
    </div>
  );
}
