import "../static/css/pageLayout.css";

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL } from "../common/globals";
import FormInput from "../components/FormInput";
import FormSelect from "../components/FormSelect";
import Sidebar from "../components/Sidebar";
import useAuth from "../hooks/useAuth";

export default function AddCounty() {
  // define rest urls used in component
  const countyAddURL = BASE_URL + "county/add";
  const countryListURL = BASE_URL + "country/list";

  const { auth } = useAuth();

  // stores user input - the properties are the names from inputs
  const [values, setValues] = useState({
    name: "",
    countryUuid: "",
  });

  // used to dynamically load the countries drop down
  const [ddOptions, setDdOptions] = useState([]);

  // list of form controls
  const inputs = [
    {
      id: 0,
      name: "name",
      type: "text",
      placeholder: "Judet",
      label: "Nume",
    },
    {
      id: 1,
      name: "countryUuid",
      type: "select",
      placeholder: "uuid tara",
      label: "Tara",
    },
  ];

  // load countries
  useEffect(() => {
    axios
      .get(countryListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptions(response.data);
      })
      .catch((error) => {
        console.log("Error loading countries");
        console.log(error);
      });
  }, []);

  const doNothing = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        countyAddURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
          params: {
            name: values.name,
            countryUuid: values.countryUuid,
            userUuid: "892ee26b-d314-11ed-bda1-902b346c1788",
          },
        }
      )
      .then((response) => {
        console.log("County Added");
        console.log(response.data);
        alert("Judet adaugat");
      })
      .catch((error) => {
        console.log("Error adding county");
        console.log(error);
        alert("Judetul nu a putut fi adaugat");
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <Link to={"/county/list"}>
        <button class="buttonClassGray">Lista judete</button>
      </Link>
      <button class="buttonClassGreen">Adauga judet</button>
      <button class="buttonClassGray">Import judete</button>

      <form class="formArea" onSubmit={handleSubmit}>
        <div class="row">
          <div class="column">
            <FormInput key={inputs[0].id} {...inputs[0]} value={values[inputs[0].name]} onChange={onChange} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        <div class="row">
          <div class="column">
            <FormSelect key={inputs[1].id} name={inputs[1].name} label={inputs[1].label} value={values[inputs[1].name]} ddOptions={ddOptions} onChange={onChange} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {/* Submit Buttons */}
        <div class="row">
          <div class="column">
            <button class="buttonClassRed" onClick={doNothing}>
              Anuleaza tara
            </button>
          </div>
          <div class="column">
            <button type="submit" class="buttonClassBlue" onClick={handleSubmit}>
              Salveaza tara
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
