import axios from "axios";
import { useState, useEffect } from "react";

import { BASE_URL } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function AddAddress() {
  // define rest urls used in component
  const addressAddURL = BASE_URL + "address/add";
  const townListURL = BASE_URL + "town/list";
  const countyListURL = BASE_URL + "county/list";

  const { auth } = useAuth();

  // stores user input - the properties are the names from inputs
  const [values, setValues] = useState({
    addressDetails1: "",
    addressDetails2: "",
    countyUuid: "",
    townUuid: "",
    postalCode: "",
  });

  // used to dynamically load the clients drop down
  const [ddOptionsTown, setDdOptionsTown] = useState([]);

  // used to dynamically load the addresses drop down
  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);

  // list of form controls
  const inputs = [
    {
      id: 1,
      name: "addressDetails1",
      type: "text",
      placeholder: "Adresa 1",
      label: "Adresa 1",
    },
    {
      id: 2,
      name: "addressDetails2",
      type: "text",
      placeholder: "Adresa 2",
      label: "Adresa 2",
    },
    {
      id: 3,
      name: "countyUuid",
      type: "selectCounty",
      placeholder: "uuid județ",
      label: "Județ",
    },
    {
      id: 4,
      name: "townUuid",
      type: "selectTown",
      placeholder: "uuid oraș",
      label: "Oraș",
    },
    {
      id: 5,
      name: "postalCode",
      type: "text",
      placeholder: "cod poștal",
      label: "Cod poștal",
    },
  ];

  // load counties
  useEffect(() => {
    axios
      .get(countyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCounty(response.data);
      })
      .catch((error) => {
        console.log("Error loading counties");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load towns
  useEffect(() => {
    axios
      .get(townListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsTown(response.data);
      })
      .catch((error) => {
        console.log("Error loading towns");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        addressAddURL,
        {
          addressDetails1: values.addressDetails1,
          addressDetails2: values.addressDetails2,
          townUuid: values.townUuid,
          countyUuid: values.countyUuid,
          postalCode: values.postalCode,
          userUUID: "892ee26b-d314-11ed-bda1-902b346c1788",
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Address Added");
        console.log(response.data);
        alert("Adresa adaugata");
      })
      .catch((error) => {
        console.log("Error adding address");
        console.log(error);
        alert("Adresa nu a putut fi adaugata");
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="AddAddress">
      <Sidebar />
      <form onSubmit={handleSubmit}>
        <h1>Adaugă adresa</h1>
        {inputs.map((input) => {
          if (input.type === "selectCounty") {
            return (
              <FormSelect
                key={input.id}
                name={input.name}
                label={input.label}
                value={values[input.name]}
                ddOptions={ddOptionsCounty}
                onChange={onChange}
              />
            );
          } else if (input.type === "selectTown") {
            return (
              <FormSelect
                key={input.id}
                name={input.name}
                label={input.label}
                value={values[input.name]}
                ddOptions={ddOptionsTown}
                onChange={onChange}
              />
            );
          } else {
            return <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} />;
          }
        })}
        <button>Submit</button>
      </form>
    </div>
  );
}
