import React from "react";
import Sidebar from "../components/Sidebar";

export const Overview = () => {
  return (
    <div className="home">
      <Sidebar />
      <h1>Overview</h1>
    </div>
  );
};

export const OverviewUsers = () => {
  return (
    <div className="home2">
      <Sidebar />
      <h1>Overview Users</h1>
    </div>
  );
};

export const OverviewRevenue = () => {
  return (
    <div className="home3">
      <Sidebar />
      <h1>Overview Revenue</h1>
    </div>
  );
};
