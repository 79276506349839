import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons, doNothing } from "../../common/globals";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListCompanies() {
  const baseURL = BASE_URL + "company/listcompanies";
  const deleteURL = BASE_URL + "company/delete";
  const downloadCompaniesExcelURL = BASE_URL + "company/export/excel";
  const downloadIscirWorkersURL = BASE_URL + "worker/list/iscir/company";
  const downloadIscirOperatorsURL = BASE_URL + "operator/list/iscir/company";
  const downloadIscirEquipmentsURL = BASE_URL + "equipment/list/iscir/company";

  const mainCompanySessionUuid = sessionStorage.getItem("mainCompanySessionUuid");

  const [list, setList] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowsWarning, setSelectedRowsWarning] = useState(false);
  const [viewRowDialog, setViewRowDialog] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    code: { value: null, matchMode: FilterMatchMode.CONTAINS },
    townName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fullAddress: { value: null, matchMode: FilterMatchMode.CONTAINS },
    countyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // authentication details
  const { auth } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(baseURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
        alert("Firmele nu au putut fi incarcate");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // start company excel export
  const handleExcelExport = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      // setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă firme.xlsx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadCompaniesExcelURL,
        {
          uuids: uuids,
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
        link.click();
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Info)}
                alt="Detalii"
                title="Detalii"
                onClick={() => viewRow(rowData)}
              />
            </td>
            <td>&nbsp;&nbsp;</td>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  // hide selected rows warning dialog
  const closeDeleteDialog = (e) => {
    setDeleteRowDialog(false);
  };

  // hide selected rows warning dialog
  const setWarningFalse = (e) => {
    setSelectedRowsWarning(false);
  };

  // hide selected rows warning dialog
  const closeDeleteDialogAndDelete = (e) => {
    const newList = list.filter(function (rowData) {
      return rowData.uuid !== selectedRow.uuid;
    });
    setList(newList);
    if (null !== mainCompanySessionUuid && mainCompanySessionUuid === selectedRow.uuid) {
      sessionStorage.removeItem("mainCompanySessionUuid");
    }
    axios
      .post(
        deleteURL,
        {
          uuid: selectedRow.uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        // setList(response.data);
      })
      .catch((error) => {
        console.log("Error deleting company");
        console.log(error);
        // alert("Certificatul nu a putut fi sters!");
      });
    setDeleteRowDialog(false);
  };

  const goToEditCompany = async (e) => {
    sessionStorage.setItem("editCompanyUuid", selectedRow.uuid);
    navigate("/company/edit");
  };

  // start iscir personel
  const handleIscirWorkers = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi auxiliari.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirWorkersURL,
        {
          uuid: uuids[0],
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  // start iscir personel
  const handleIscirOperators = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă personal ISCIR - deservenţi.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirOperatorsURL,
        {
          uuid: uuids[0],
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  // start iscir personel
  const handleIscirEquipments = async (e) => {
    e.preventDefault();
    if (selectedRows === null || selectedRows.length === 0) {
      setSelectedRowsWarning(true);
      return;
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Listă evidenţă instalaţii ISCIR.docx";
    let uuids = selectedRows.map((row) => {
      return row.uuid;
    });
    axios
      .post(
        downloadIscirEquipmentsURL,
        {
          uuid: uuids[0],
        },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }));
        link.click();
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Listă firme</button>
      <div class="spaceDiv" />
      <img src={require("../../static/images/buttons/" + ICONS.CompanyListDisabled)} alt="Listă firme" title="Listă firme" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyExport)}
        alt="Exportă firme"
        title="Exportă firme"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration)}
        alt="Listă evidenţă instalaţii ISCIR"
        title="Listă evidenţă instalaţii ISCIR"
        onClick={handleIscirEquipments}
      />
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="name" filter filterPlaceholder="Caută" sortable header="Denumire firmă"></Column>
          <Column field="code" filter filterPlaceholder="Caută" sortable header="Cod sediu social"></Column>
          <Column field="townName" filter filterPlaceholder="Caută" sortable header="Localitate sediu social"></Column>
          <Column field="fullAddress" filter filterPlaceholder="Caută" sortable header="Adresă sediu social"></Column>
          <Column field="countyName" filter filterPlaceholder="Caută" sortable header="Judeţ/sector sediu social"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <img src={require("../../static/images/buttons/" + ICONS.CompanyListDisabled)} alt="Listă firme" title="Listă firme" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/register"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyAdd)} alt="Adaugă firmă" title="Adaugă firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/company/import"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.CompanyImport)}
          alt="Importă firme/puncte de lucru"
          title="Importă firme/puncte de lucru"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.CompanyExport)}
        alt="Exportă firme"
        title="Exportă firme"
        onClick={doNothing}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.OperatorRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi"
        title="Listă evidenţă personal ISCIR - deservenţi"
        onClick={handleIscirOperators}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.WorkerRegistration)}
        alt="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        title="Listă evidenţă personal ISCIR - deservenţi auxiliari"
        onClick={handleIscirWorkers}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        class="imgtoolbar"
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingRegistration)}
        alt="Listă evidenţă instalaţii ISCIR"
        title="Listă evidenţă instalaţii ISCIR"
        onClick={handleIscirEquipments}
      />
      <div class="spaceDiv" />
      <Dialog
        visible={viewRowDialog}
        modal
        header={dialogHeaderElement("Detalii firmă")}
        footer={dialogFooterContent("OK", () => setViewRowDialog(false))}
        style={{ width: "50rem" }}
        onHide={() => setViewRowDialog(false)}
      >
        <table>
          <tbody>
            <tr>
              <td class="tdleft"></td>
              <td class="tdrigth">
                <img
                  class="imgtoolbar"
                  src={require("../../static/images/buttons/" + ICONS.CompanyEdit)}
                  alt="Modifică firmă"
                  title="Modifică firmă"
                  onClick={() => goToEditCompany()}
                />
              </td>
            </tr>
            <tr>
              <td class="tdleft">Denumire firmă:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.name : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Cod sediu social:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.code : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Număr înregistrare:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.registrationNumber : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Cod Fiscal:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.fiscalCode : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Adresă sediu social:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.fullAddress : ""}</td>
            </tr>
            <tr>
              <td class="tdleft">Telefon:</td>
              <td class="tdrigth">{null != selectedRow ? selectedRow.phone : ""}</td>
            </tr>
          </tbody>
        </table>
      </Dialog>
      <Dialog
        visible={deleteRowDialog}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContentTwoButtons("Da", closeDeleteDialogAndDelete, "Nu", closeDeleteDialog)}
        style={{ width: "50rem" }}
        onHide={() => setDeleteRowDialog(false)}
      >
        <p className="m-0">Doreşti să ştergi firma: {null != selectedRow ? selectedRow.name : ""} ?</p>
      </Dialog>
      <Dialog
        visible={selectedRowsWarning}
        modal
        header={dialogHeaderElement("Atenţie")}
        footer={dialogFooterContent("OK", setWarningFalse)}
        style={{ width: "50rem" }}
        onHide={() => setSelectedRowsWarning(false)}
      >
        <p className="m-0">Selectează o firmă!</p>
      </Dialog>
    </div>
  );
}
