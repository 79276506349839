import React from "react";
import { Link } from "react-router-dom";

import { ICONS } from "../common/globals";

const Footer = () => {
  return (
    <footer class="footer">
      <div>
        <img class="logo-at" src={require("../static/images/" + ICONS.LogoRsvti)} alt="Asisco Tehnic SRL" />

        <input class="side-menu" type="checkbox" id="side-menu" />
        <label for="side-menu">
          <span></span>
        </label>

        <nav class="nav">
          <ul class="menu">
            <li>
              <Link to={"/public/prices"}>Termeni și condiții</Link>
            </li>
            <li>
              <Link to={"/public/prices"}>Politica de confidențialitate</Link>
            </li>
            <li>
              <Link to={"/public/contact"}></Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
