import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import ProcessFormErrors from "../../components/ProcessFormErrors";

export default function AddEquipmentPressure() {
  const baseURL = BASE_URL + "equipment/pressure/add";
  const companyListURL = BASE_URL + "company/listsimplecompaniesandbranches";
  const equipmentRegisterListURL = BASE_URL + "structure/equipment/register";
  const equipmentStatusListURL = BASE_URL + "structure/equipment/status";
  const equipmentPressureListURL = BASE_URL + "structure/equipment/pressure";
  const equipmentPressureParamsURL = BASE_URL + "structure/equipment/pressure/params";
  const structureChildrenURL = BASE_URL + "structure/worker/children";
  const countyListURL = BASE_URL + "county/list";
  const townListURL = BASE_URL + "town/listbycounty";
  const countryListURL = BASE_URL + "country/list";
  const uploadEquipmentInspectionURL = BASE_URL + "equipment/pressure/authorization/upload";
  const assemblyCompaniesListURL = BASE_URL + "iscircompany/pressureassemblycompanies";
  const maintenanceCompaniesListURL = BASE_URL + "iscircompany/pressuremaintenancecompanies";
  const repairCompaniesListURL = BASE_URL + "iscircompany/pressurerepaircompanies";
  const verifyCompaniesListURL = BASE_URL + "iscircompany/pressureverifycompanies";
  const allCompaniesListURL = BASE_URL + "iscircompany/pressureallcompanies";
  const assemblyPersonsListURL = BASE_URL + "iscircompanyperson/pressureassemblypersons";
  const maintenancePersonsListURL = BASE_URL + "iscircompanyperson/pressuremaintenancepersons";
  const repairPersonsListURL = BASE_URL + "iscircompanyperson/pressurerepairpersons";
  const verifyPersonsListURL = BASE_URL + "iscircompanyperson/pressureverifypersons";
  const allPersonsListURL = BASE_URL + "iscircompanyperson/pressureallpersons";

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [ddOptionsEquipmentRegister, setDdOptionsEquipmentRegister] = useState([]);
  const [ddOptionsEquipmentStatus, setDdOptionsEquipmentStatus] = useState([]);
  const [ddOptionsEquipmentClassification, setDdOptionsEquipmentClassification] = useState([]);
  const [ddOptionsEquipmentClass, setDdOptionsEquipmentClass] = useState([]);
  const [ddOptionsEquipmentGroup, setDdOptionsEquipmentGroup] = useState([]);
  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);
  const [ddOptionsTown, setDdOptionsTown] = useState([]);
  const [ddOptionsPrescriptions, setDdOptionsPrescriptions] = useState([]);
  const [params, setParams] = useState([]);
  const [ddOptionsCountry, setDdOptionsCountry] = useState([]);
  const [noOfValves, setNoOfValves] = useState(["New Valve"]);
  const [fileInspection, setFileInspection] = useState(null);
  const [assemblyCompanies, setAssemblyCompanies] = useState([]);
  const [assemblyPersons, setAssemblyPersons] = useState([]);
  const [maintenanceCompanies, setMaintenanceCompanies] = useState([]);
  const [maintenancePersons, setMaintenancePersons] = useState([]);
  const [maintenancePersons2, setMaintenancePersons2] = useState([]);
  const [repairCompanies, setRepairCompanies] = useState([]);
  const [repairPersons, setRepairPersons] = useState([]);
  const [repairPersons2, setRepairPersons2] = useState([]);
  const [verifyCompanies, setVerifyCompanies] = useState([]);
  const [verifyPersons, setVerifyPersons] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allPersons, setAllPersons] = useState([]);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const { auth } = useAuth();

  const [values, setValues] = useState({
    prescriptionUuid: "",
    companyBranchUuid: "",
    registrationUuid: "",
    statusUuid: "",
    equipmentClassificationUuid: "",
    equipmentClassUuid: "",
    equipmentGroupUuid: "",
    description: "",
    iscirRegistrationNumber: "",
    model: "",
    manufacturingSeries: "",
    manufacturedBy: "",
    manufacturingCountryUuid: "",
    manufacturingYear: "",
    inspectionNumber: "",
    inspectionDate: "",
    inspectionName: "",
    inspectionCompany: "",
    interiorInspectionDueDate: "",
    pressureInspectionDueDate: "",
    probeCasaNumber: "",
    probeCasaDate: "",
    probeCasaCompanyUuid: "",
    probeCasaPersonUuid: "",
    maintenanceReportNumber: "",
    maintenanceReportDate: "",
    maintenanceReportCompanyUuid: "",
    maintenanceReportPersonUuid: "",
    fixingReportNumber: "",
    fixingReportDate: "",
    fixingReportCompanyUuid: "",
    fixingReportPersonUuid: "",
    assembleCompanyUuid: "",
    assemblePersonUuid: "",
    maintenanceCompanyUuid: "",
    maintenancePersonUuid: "",
    fixingCompanyUuid: "",
    fixingPersonUuid: "",
    ownerCompanyName: "",
    ownerCompanyCountyUuid: "",
    ownerCompanyTownUuid: "",
    ownerPhone: "",
    ownerEmail: "",
    ownerRslLastName: "",
    ownerRslFirstName: "",
    ownerRslPhone: "",
    ownerRslEmail: "",
    verifyPVNumber: "",
    verifyPVDate: "",
    verifyInspectorName: "",
    verifyExtendYears: "",
    verifyExtendDate: "",
    verifyReportNumber: "",
    verifyReportDate: "",
    verifyIscirCompanyUuid: "",
    verifyIscirPersonUuid: "",
    internInspectionReportNumber: "",
    internInspectionReportDate: "",
    internInspectionRVTName: "",
    internInspectionCompany: "",
    internInspectionPVNumber: "",
    internInspectionPVDate: "",
    internInspectionDueDate: "",
    type0: "",
    series00: "",
    verificationNumber0: "",
    verificationDate0: "",
    pressure0: "",
    company0: "",
  });

  const inputs = [
    {
      id: 0,
      name: "prescriptionUuid",
      type: "selectClass",
      placeholder: "Prescripție tehnică aplicabilă",
      label: "Prescripție tehnică aplicabilă",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 1,
      name: "companyBranchUuid",
      type: "selectClass",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 2,
      name: "registrationUuid",
      type: "selectClass",
      placeholder: "Înregistrare echipament sub presiune",
      label: "Înregistrare echipament sub presiune",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 3,
      name: "statusUuid",
      type: "selectClass",
      placeholder: "Stare echipament sub presiune",
      label: "Stare echipament sub presiune",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 4,
      name: "equipmentClassificationUuid",
      type: "text",
      placeholder: "Clasificare echipament sub presiune",
      label: "Clasificare echipament sub presiune",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 5,
      name: "equipmentClassUuid",
      type: "selectClass",
      placeholder: "Clasa echipament sub presiune (dacă este cazul)",
      label: "Clasa echipament sub presiune (dacă este cazul)",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 6,
      name: "equipmentGroupUuid",
      type: "selectClass",
      placeholder: "Grupă echipament sub presiune (dacă este cazul)",
      label: "Grupă echipament sub presiune (dacă este cazul)",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 7,
      name: "description",
      type: "text",
      placeholder: "Descriere echipament sub presiune",
      label: "Descriere echipament sub presiune",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 8,
      name: "iscirRegistrationNumber",
      type: "text",
      placeholder: "Număr înregistrare ISCIR",
      label: "Număr înregistrare ISCIR",
    },
    {
      id: 9,
      name: "model",
      type: "text",
      placeholder: "Model echipament sub presiune",
      label: "Model echipament sub presiune",
    },
    {
      id: 10,
      name: "manufacturingSeries",
      type: "text",
      placeholder: "Număr serie echipament sub presiune",
      label: "Număr serie echipament sub presiune",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 11,
      name: "manufacturedBy",
      type: "text",
      placeholder: "Producător echipament sub presiune",
      label: "Producător echipament sub presiune",
    },
    {
      id: 12,
      name: "manufacturingCountryUuid",
      type: "selectClass",
      placeholder: "Ţară producător echipament sub presiune",
      label: "Ţară producător echipament sub presiune",
    },
    {
      id: 13,
      name: "manufacturingYear",
      type: "text",
      placeholder: "An de fabricație echipament sub presiune",
      label: "An de fabricație echipament sub presiune",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 14,
      name: "inspectionNumber",
      type: "text",
      placeholder: "Număr raport inspecție",
      label: "Număr raport inspecție",
    },
    {
      id: 15,
      name: "inspectionDate",
      type: "date",
      placeholder: "Dată raport inspecție",
      label: "Dată raport inspecție",
    },
    {
      id: 16,
      name: "inspectionName",
      type: "text",
      placeholder: "Nume inspector",
      label: "Nume inspector",
    },
    {
      id: 17,
      name: "inspectionCompany",
      type: "text",
      placeholder: "Firma care a emis raportul de inspecție",
      label: "Firma care a emis raportul de inspecție",
    },
    {
      id: 18,
      name: "interiorInspectionDueDate",
      type: "date",
      placeholder: "Scadență RI autorizație echipament sub presiune",
      label: "Scadență RI autorizație echipament sub presiune",
    },
    {
      id: 19,
      name: "pressureInspectionDueDate",
      type: "date",
      placeholder: "Scadență PH autorizație echipament sub presiune",
      label: "Scadență PH autorizație echipament sub presiune",
    },
    {
      id: 20,
      name: "probeCasaNumber",
      type: "text",
      placeholder: "Număr raport",
      label: "Număr raport",
    },
    {
      id: 21,
      name: "probeCasaDate",
      type: "date",
      placeholder: "Dată raport",
      label: "Dată raport",
    },
    {
      id: 22,
      name: "probeCasaCompanyUuid",
      type: "selectClass",
      placeholder: "Firma care a emis procesul verbal",
      label: "Firma care a emis procesul verbal",
    },
    {
      id: 23,
      name: "probeCasaPersonUuid",
      type: "selectClass",
      placeholder: "Nume RSL",
      label: "Nume RSL",
    },
    {
      id: 24,
      name: "maintenanceReportNumber",
      type: "text",
      placeholder: "Număr raport",
      label: "Număr raport",
    },
    {
      id: 25,
      name: "maintenanceReportDate",
      type: "date",
      placeholder: "Dată raport",
      label: "Dată raport",
    },
    {
      id: 26,
      name: "maintenanceReportCompanyUuid",
      type: "selectClass",
      placeholder: "Firma care a emis raportul",
      label: "Firma care a emis raportul",
    },
    {
      id: 27,
      name: "maintenanceReportPersonUuid",
      type: "selectClass",
      placeholder: "Nume RSL",
      label: "Nume RSL",
    },
    {
      id: 28,
      name: "fixingReportNumber",
      type: "text",
      placeholder: "Număr raport",
      label: "Număr raport",
    },
    {
      id: 29,
      name: "fixingReportDate",
      type: "date",
      placeholder: "Dată raport",
      label: "Dată raport",
    },
    {
      id: 30,
      name: "fixingReportCompanyUuid",
      type: "selectClass",
      placeholder: "Firma care a emis raportul",
      label: "Firma care a emis raportul",
    },
    {
      id: 31,
      name: "fixingReportPersonUuid",
      type: "selectClass",
      placeholder: "Nume RSL",
      label: "Nume RSL",
    },
    {
      id: 32,
      name: "assembleCompanyUuid",
      type: "selectClass",
      placeholder: "Denumire firmă montaj",
      label: "Denumire firmă montaj",
    },
    {
      id: 33,
      name: "assemblePersonUuid",
      type: "selectClass",
      placeholder: "Nume RSL montaj",
      label: "Nume RSL montaj",
    },
    {
      id: 34,
      name: "maintenanceCompanyUuid",
      type: "selectClass",
      placeholder: "Denumire firmă întreţinere",
      label: "Denumire firmă întreţinere",
    },
    {
      id: 35,
      name: "maintenancePersonUuid",
      type: "selectClass",
      placeholder: "Nume RSL întreţinere",
      label: "Nume RSL întreţinere",
    },
    {
      id: 36,
      name: "fixingCompanyUuid",
      type: "selectClass",
      placeholder: "Denumire firmă reparare",
      label: "Denumire firmă reparare",
    },
    {
      id: 37,
      name: "fixingPersonUuid",
      type: "selectClass",
      placeholder: "Nume RSL reparare",
      label: "Nume RSL reparare",
    },
    {
      id: 38,
      name: "ownerCompanyName",
      type: "text",
      placeholder: "Denumire firmă",
      label: "Denumire firmă",
    },
    {
      id: 39,
      name: "ownerCompanyCountyUuid",
      type: "selectClass",
      placeholder: "Județ/sector",
      label: "Județ/sector",
    },
    {
      id: 40,
      name: "ownerCompanyTownUuid",
      type: "selectClass",
      placeholder: "Oraș",
      label: "Oraș",
    },
    {
      id: 41,
      name: "ownerPhone",
      type: "text",
      placeholder: "Număr de telefon contact firmă",
      label: "Număr de telefon contact firmă",
    },
    {
      id: 42,
      name: "ownerEmail",
      type: "text",
      placeholder: "Adresă e-mail contact firmă",
      label: "Adresă e-mail contact firmă",
    },
    {
      id: 43,
      name: "ownerRslLastName",
      type: "text",
      placeholder: "Nume familie RSL",
      label: "Nume familie RSL",
    },
    {
      id: 44,
      name: "ownerRslFirstName",
      type: "text",
      placeholder: "Prenume RSL",
      label: "Prenume RSL",
    },
    {
      id: 45,
      name: "ownerRslPhone",
      type: "text",
      placeholder: "Număr telefon mobil RSL",
      label: "Număr telefon mobil RSL",
    },
    {
      id: 46,
      name: "ownerRslEmail",
      type: "text",
      placeholder: "Adresă e-mail RSL",
      label: "Adresă e-mail RSL",
    },
    {
      id: 47,
      name: "verifyPVNumber",
      type: "text",
      placeholder: "Numar proces verbal ISCIR verificare raport tehnic",
      label: "Numar proces verbal ISCIR verificare raport tehnic",
    },
    {
      id: 48,
      name: "verifyPVDate",
      type: "date",
      placeholder: "Data proces verbal ISCIR verificare raport tehnic",
      label: "Data proces verbal ISCIR verificare raport tehnic",
    },
    {
      id: 49,
      name: "verifyInspectorName",
      type: "text",
      placeholder: "Nume inspector ISCIR ",
      label: "Nume inspector ISCIR ",
    },
    {
      id: 50,
      name: "verifyExtendYears",
      type: "text",
      placeholder: "Numar ani prelungire durata de functionare (conform PV)",
      label: "Numar ani prelungire durata de functionare (conform PV)",
    },
    {
      id: 51,
      name: "verifyExtendDate",
      type: "date",
      placeholder: "Data prelungire durata de functionare (conform PV)",
      label: "Data prelungire durata de functionare (conform PV)",
    },
    {
      id: 52,
      name: "verifyReportNumber",
      type: "text",
      placeholder: "Numar raport",
      label: "Numar raport",
    },
    {
      id: 53,
      name: "verifyReportDate",
      type: "date",
      placeholder: "Data raport",
      label: "Data raport",
    },
    {
      id: 54,
      name: "verifyIscirCompanyUuid",
      type: "text",
      placeholder: "Denumire firma",
      label: "Denumire firma",
    },
    {
      id: 55,
      name: "verifyIscirPersonUuid",
      type: "text",
      placeholder: "Persoana RADTE-IR",
      label: "Persoana RADTE-IR",
    },
    {
      id: 56,
      name: "internInspectionReportNumber",
      type: "text",
      placeholder: "Numar raport verificare",
      label: "Numar raport verificare",
    },
    {
      id: 57,
      name: "internInspectionReportDate",
      type: "date",
      placeholder: "Data raport verificare",
      label: "Data raport verificare",
    },
    {
      id: 58,
      name: "internInspectionRVTName",
      type: "text",
      placeholder: "Nume RVT firma verificare",
      label: "Nume RVT firma verificare",
    },
    {
      id: 59,
      name: "internInspectionCompany",
      type: "text",
      placeholder: "Firma care a emis raportul de inspectie",
      label: "Firma care a emis raportul de inspectie",
    },
    {
      id: 60,
      name: "internInspectionPVNumber",
      type: "text",
      placeholder: "Numar PV RSVTI",
      label: "Numar PV RSVTI",
    },
    {
      id: 61,
      name: "internInspectionPVDate",
      type: "date",
      placeholder: "Data PV RSVTI",
      label: "Data PV RSVTI",
    },
    {
      id: 62,
      name: "internInspectionDueDate",
      type: "date",
      placeholder: "Scadenta autorizatie echipament sub presiune",
      label: "Scadenta autorizatie echipament sub presiune",
    },
  ];

  const FIELD = {
    prescriptionUuid: 0,
    companyBranchUuid: 1,
    registrationUuid: 2,
    statusUuid: 3,
    equipmentClassificationUuid: 4,
    equipmentClassUuid: 5,
    equipmentGroupUuid: 6,
    description: 7,
    iscirRegistrationNumber: 8,
    model: 9,
    manufacturingSeries: 10,
    manufacturedBy: 11,
    manufacturingCountryUuid: 12,
    manufacturingYear: 13,
    inspectionNumber: 14,
    inspectionDate: 15,
    inspectionName: 16,
    inspectionCompany: 17,
    interiorInspectionDueDate: 18,
    pressureInspectionDueDate: 19,
    probeCasaNumber: 20,
    probeCasaDate: 21,
    probeCasaCompanyUuid: 22,
    probeCasaPersonUuid: 23,
    maintenanceReportNumber: 24,
    maintenanceReportDate: 25,
    maintenanceReportCompanyUuid: 26,
    maintenanceReportPersonUuid: 27,
    fixingReportNumber: 28,
    fixingReportDate: 29,
    fixingReportCompanyUuid: 30,
    fixingReportPersonUuid: 31,
    assembleCompanyUuid: 32,
    assemblePersonUuid: 33,
    maintenanceCompanyUuid: 34,
    maintenancePersonUuid: 35,
    fixingCompanyUuid: 36,
    fixingPersonUuid: 37,
    ownerCompanyName: 38,
    ownerCompanyCountyUuid: 39,
    ownerCompanyTownUuid: 40,
    ownerPhone: 41,
    ownerEmail: 42,
    ownerRslLastName: 43,
    ownerRslFirstName: 44,
    ownerRslPhone: 45,
    ownerRslEmail: 46,
    verifyPVNumber: 47,
    verifyPVDate: 48,
    verifyInspectorName: 49,
    verifyExtendYears: 50,
    verifyExtendDate: 51,
    verifyReportNumber: 52,
    verifyReportDate: 53,
    verifyIscirCompanyUuid: 54,
    verifyIscirPersonUuid: 55,
    internInspectionReportNumber: 56,
    internInspectionReportDate: 57,
    internInspectionRVTName: 58,
    internInspectionCompany: 59,
    internInspectionPVNumber: 60,
    internInspectionPVDate: 61,
    internInspectionDueDate: 62,
  };

  // load configs
  useEffect(() => {
    axios
      .get(countryListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCountry(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .get(countyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCounty(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        equipmentRegisterListURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsEquipmentRegister(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        equipmentStatusListURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsEquipmentStatus(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        equipmentPressureListURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsPrescriptions(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadIscirCompanies = (prescriptionUuid) => {
    axios
      .post(
        assemblyCompaniesListURL,
        {
          uuid: prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setAssemblyCompanies(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        maintenanceCompaniesListURL,
        {
          uuid: prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setMaintenanceCompanies(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        repairCompaniesListURL,
        {
          uuid: prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setRepairCompanies(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        verifyCompaniesListURL,
        {
          uuid: prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setVerifyCompanies(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        allCompaniesListURL,
        {
          uuid: prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setAllCompanies(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onChangeCounty = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "" });
    setDdOptionsTown([]);
    axios
      .post(
        townListURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsTown(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  useEffect(() => {
    if (ddOptionsEquipmentRegister.length > 0) {
      setValues({ ...values, registrationUuid: "0431b4b1-6f5f-11ee-9c97-902b346c1788" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddOptionsEquipmentRegister]);

  const onChangeEquipmentPrescriptions = (e) => {
    let dataOld = [];
    dataOld = { ...dataOld, assembleCompanyUuid: "", assemblePersonUuid: "" };
    dataOld = { ...dataOld, maintenanceCompanyUuid: "", maintenancePersonUuid: "" };
    dataOld = { ...dataOld, fixingCompanyUuid: "", fixingPersonUuid: "" };
    dataOld = { ...dataOld, verifyIscirCompanyUuid: "", verifyIscirPersonUuid: "" };
    dataOld = { ...dataOld, probeCasaCompanyUuid: "", probeCasaPersonUuid: "" };
    dataOld = { ...dataOld, maintenanceReportCompanyUuid: "", maintenanceReportPersonUuid: "" };
    dataOld = { ...dataOld, fixingReportCompanyUuid: "", fixingReportPersonUuid: "" };
    setAssemblyCompanies([]);
    setAssemblyPersons([]);
    setMaintenanceCompanies([]);
    setMaintenancePersons([]);
    setRepairCompanies([]);
    setRepairPersons([]);
    setVerifyCompanies([]);
    setVerifyPersons([]);
    loadIscirCompanies(e.target.value);
    axios
      .post(
        equipmentPressureParamsURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (var i = 0; i < response.data.length; i++) {
          data = { ...data, ["type" + i]: response.data[i].uuid, ["value" + i]: "" };
        }
        if (params !== null && params.length > response.data.length) {
          for (i = response.data.length; i < params.length; i++) {
            data = { ...data, ["type" + i]: "", ["value" + i]: "" };
          }
        }
        setValues({
          ...values,
          ...data,
          ...dataOld,
          [e.target.name]: e.target.value,
          equipmentClassificationUuid: "",
          equipmentClassUuid: "",
          equipmentGroupUuid: "",
        });
        setParams(response.data);
      })
      .catch((error) => {
        setValues({
          ...values,
          ...dataOld,
          [e.target.name]: e.target.value,
          equipmentClassificationUuid: "",
          equipmentClassUuid: "",
          equipmentGroupUuid: "",
        });
        setConfigNotProcessedDialog(true);
      });
    setDdOptionsEquipmentClass([]);
    setDdOptionsEquipmentGroup([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsEquipmentClassification(response.data.values);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeEquipmentClassification = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassUuid: "", equipmentGroupUuid: "" });
    setDdOptionsEquipmentClass([]);
    setDdOptionsEquipmentGroup([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (response.data.type === "equipment class") {
          setDdOptionsEquipmentClass(response.data.values);
        } else {
          setDdOptionsEquipmentGroup(response.data.values);
        }
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeEquipmentClass = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroupUuid: "" });
    setDdOptionsEquipmentGroup([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsEquipmentGroup(response.data.values);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    let data = [];
    for (let i = 0; i < params.length; i++) {
      let newfield = { typeValue: values["typeValue" + i], value: values["value" + i] };
      data = [...data, newfield];
    }
    let data2 = [];
    for (let i = 0; i < noOfValves.length; i++) {
      let newfield = {
        type: values["type" + i],
        series: values["series" + i],
        verificationNumber: values["verificationNumber" + i],
        verificationDate: values["verificationDate" + i],
        pressure: values["pressure" + i],
        company: values["company" + i],
      };
      data2 = [...data2, newfield];
    }
    axios
      .post(
        baseURL,
        {
          prescriptionUuid: values.prescriptionUuid,
          companyBranchUuid: values.companyBranchUuid,
          registrationUuid: values.registrationUuid,
          statusUuid: values.statusUuid,
          equipmentClassificationUuid: values.equipmentClassificationUuid,
          equipmentClassUuid: values.equipmentClassUuid,
          equipmentGroupUuid: values.equipmentGroupUuid,
          description: values.description,
          iscirRegistrationNumber: values.iscirRegistrationNumber,
          model: values.model,
          manufacturingSeries: values.manufacturingSeries,
          manufacturedBy: values.manufacturedBy,
          manufacturingCountryUuid: values.manufacturingCountryUuid,
          manufacturingYear: values.manufacturingYear,
          inspectionNumber: values.inspectionNumber,
          inspectionDate: values.inspectionDate,
          inspectionName: values.inspectionName,
          inspectionCompany: values.inspectionCompany,
          interiorInspectionDueDate: values.interiorInspectionDueDate,
          pressureInspectionDueDate: values.pressureInspectionDueDate,
          probeCasaNumber: values.probeCasaNumber,
          probeCasaDate: values.probeCasaDate,
          probeCasaCompanyUuid: values.probeCasaCompanyUuid,
          probeCasaPersonUuid: values.probeCasaPersonUuid,
          maintenanceReportNumber: values.maintenanceReportNumber,
          maintenanceReportDate: values.maintenanceReportDate,
          maintenanceReportCompanyUuid: values.maintenanceReportCompanyUuid,
          maintenanceReportPersonUuid: values.maintenanceReportPersonUuid,
          fixingReportNumber: values.fixingReportNumber,
          fixingReportDate: values.fixingReportDate,
          fixingReportCompanyUuid: values.fixingReportCompanyUuid,
          fixingReportPersonUuid: values.fixingReportPersonUuid,
          assembleCompanyUuid: values.assembleCompanyUuid,
          assemblePersonUuid: values.assemblePersonUuid,
          maintenanceCompanyUuid: values.maintenanceCompanyUuid,
          maintenancePersonUuid: values.maintenancePersonUuid,
          fixingCompanyUuid: values.fixingCompanyUuid,
          fixingPersonUuid: values.fixingPersonUuid,
          ownerCompanyName: values.ownerCompanyName,
          ownerCompanyCountyUuid: values.ownerCompanyCountyUuid,
          ownerCompanyTownUuid: values.ownerCompanyTownUuid,
          ownerPhone: values.ownerPhone,
          ownerEmail: values.ownerEmail,
          ownerRslLastName: values.ownerRslLastName,
          ownerRslFirstName: values.ownerRslFirstName,
          ownerRslPhone: values.ownerRslPhone,
          ownerRslEmail: values.ownerRslEmail,
          verifyPVNumber: values.verifyPVNumber,
          verifyPVDate: values.verifyPVDate,
          verifyInspectorName: values.verifyInspectorName,
          verifyExtendYears: values.verifyExtendYears,
          verifyExtendDate: values.verifyExtendDate,
          verifyReportNumber: values.verifyReportNumber,
          verifyReportDate: values.verifyReportDate,
          verifyIscirCompanyUuid: values.verifyIscirCompanyUuid,
          verifyIscirPersonUuid: values.verifyIscirPersonUuid,
          internInspectionReportNumber: values.internInspectionReportNumber,
          internInspectionReportDate: values.internInspectionReportDate,
          internInspectionRVTName: values.internInspectionRVTName,
          internInspectionCompany: values.internInspectionCompany,
          internInspectionPVNumber: values.internInspectionPVNumber,
          internInspectionPVDate: values.internInspectionPVDate,
          internInspectionDueDate: values.internInspectionDueDate,
          params: data,
          valves: data2,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        uploadFileInspection(res.data);
        let file = document.querySelector(".fileI");
        if (file !== null) {
          file.value = "";
        }
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.prescriptionUuid)) {
      data.push({ id: inputs[FIELD.prescriptionUuid].id, value: inputs[FIELD.prescriptionUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.companyBranchUuid)) {
      data.push({ id: inputs[FIELD.companyBranchUuid].id, value: inputs[FIELD.companyBranchUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.registrationUuid)) {
      data.push({ id: inputs[FIELD.registrationUuid].id, value: inputs[FIELD.registrationUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.statusUuid)) {
      data.push({ id: inputs[FIELD.statusUuid].id, value: inputs[FIELD.statusUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.equipmentClassificationUuid)) {
      data.push({
        id: inputs[FIELD.equipmentClassificationUuid].id,
        value: inputs[FIELD.equipmentClassificationUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY,
      });
      result = false;
    }
    if (isEmptyString(values.description)) {
      data.push({
        id: inputs[FIELD.description].id,
        value: inputs[FIELD.description].label + STRING_CONST.VALIDATION_IS_MANDATORY,
      });
      result = false;
    }
    if (!validateString(values.description, FIELD_SIZE.Zero, FIELD_SIZE.EquipmentDescriptionMax)) {
      data.push({
        id: inputs[FIELD.description].id,
        value: inputs[FIELD.description].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EquipmentDescriptionMax),
      });
      result = false;
    }
    if (isEmptyString(values.manufacturingSeries)) {
      data.push({
        id: inputs[FIELD.manufacturingSeries].id,
        value: inputs[FIELD.manufacturingSeries].label + STRING_CONST.VALIDATION_IS_MANDATORY,
      });
      result = false;
    }
    if (!validateString(values.manufacturingSeries, FIELD_SIZE.Zero, FIELD_SIZE.EquipmentManufacturingSeriesMax)) {
      data.push({
        id: inputs[FIELD.manufacturingSeries].id,
        value: inputs[FIELD.manufacturingSeries].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EquipmentManufacturingSeriesMax),
      });
      result = false;
    }
    if (isEmptyString(values.manufacturingYear)) {
      data.push({
        id: inputs[FIELD.manufacturingYear].id,
        value: inputs[FIELD.manufacturingYear].label + STRING_CONST.VALIDATION_IS_MANDATORY,
      });
      result = false;
    }
    if (!validateString(values.manufacturingYear, FIELD_SIZE.Zero, FIELD_SIZE.EquipmentManufacturingYearMax)) {
      data.push({
        id: inputs[FIELD.manufacturingYear].id,
        value: inputs[FIELD.manufacturingYear].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EquipmentManufacturingYearMax),
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  const uploadFileInspection = (equipmentAuthorisationUuid) => {
    if (fileInspection === null) {
      setProcessingDialog(false);
      setProcessedDialog(true);
      return;
    }
    const formData = new FormData();
    formData.append("equipmentAuthorisationUuid", equipmentAuthorisationUuid);
    formData.append("fileInspection", fileInspection);
    axios
      .post(uploadEquipmentInspectionURL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        setProcessingDialog(false);
        setProcessedDialog(true);
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const addValve = (e) => {
    e.preventDefault();
    if (noOfValves.length < 5) {
      setValues({
        ...values,
        ["type" + noOfValves.length]: "",
        ["series" + noOfValves.length]: "",
        ["verificationNumber" + noOfValves.length]: "",
        ["verificationDate" + noOfValves.length]: "",
        ["pressure" + noOfValves.length]: "",
        ["company" + noOfValves.length]: "",
      });
      setNoOfValves([...noOfValves, "New Valve"]);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeAssembly = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        assemblyPersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setAssemblyPersons(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeMaintenance = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        maintenancePersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setMaintenancePersons(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeRepair = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        repairPersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setRepairPersons(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeVerify = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        verifyPersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setVerifyPersons(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };
  const onChangeMaintenance2 = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        maintenancePersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setMaintenancePersons2(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeRepair2 = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        repairPersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setRepairPersons2(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeAll = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    axios
      .post(
        allPersonsListURL,
        {
          iscirCompanyUuid: e.target.value,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setAllPersons(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Adaugă echipament</button>
      <div class="spaceDiv" />
      <Link to={"/equipment/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentPressureList)} alt="Listă echipamente" title="Listă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAddDisabled)}
        alt="Adaugă echipament sub presiune"
        title="Adaugă echipament sub presiune"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentPressureImport)} alt="Importă echipamente" title="Importă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExportDisabled)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      <form class="formArea">
        <div class="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii informații principale instalație sub presiune">
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.prescriptionUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.prescriptionUuid].id}
                    name={inputs[FIELD.prescriptionUuid].name}
                    label={inputs[FIELD.prescriptionUuid].label}
                    value={values[inputs[FIELD.prescriptionUuid].name]}
                    mandatory={inputs[FIELD.prescriptionUuid].mandatory}
                    ddOptions={ddOptionsPrescriptions}
                    onChange={onChangeEquipmentPrescriptions}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.companyBranchUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.companyBranchUuid].id}
                    name={inputs[FIELD.companyBranchUuid].name}
                    label={inputs[FIELD.companyBranchUuid].label}
                    value={values[inputs[FIELD.companyBranchUuid].name]}
                    mandatory={inputs[FIELD.companyBranchUuid].mandatory}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Last Name and First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.registrationUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.registrationUuid].id}
                    name={inputs[FIELD.registrationUuid].name}
                    label={inputs[FIELD.registrationUuid].label}
                    value={values[inputs[FIELD.registrationUuid].name]}
                    mandatory={inputs[FIELD.registrationUuid].mandatory}
                    ddOptions={ddOptionsEquipmentRegister}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.statusUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.statusUuid].id}
                    name={inputs[FIELD.statusUuid].name}
                    label={inputs[FIELD.statusUuid].label}
                    value={values[inputs[FIELD.statusUuid].name]}
                    mandatory={inputs[FIELD.statusUuid].mandatory}
                    ddOptions={ddOptionsEquipmentStatus}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Last Name and First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentClassificationUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassificationUuid].id}
                    name={inputs[FIELD.equipmentClassificationUuid].name}
                    label={inputs[FIELD.equipmentClassificationUuid].label}
                    value={values[inputs[FIELD.equipmentClassificationUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassificationUuid].mandatory}
                    ddOptions={ddOptionsEquipmentClassification}
                    onChange={onChangeEquipmentClassification}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentClassUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassUuid].id}
                    name={inputs[FIELD.equipmentClassUuid].name}
                    label={inputs[FIELD.equipmentClassUuid].label}
                    value={values[inputs[FIELD.equipmentClassUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassUuid].mandatory}
                    ddOptions={ddOptionsEquipmentClass}
                    onChange={onChangeEquipmentClass}
                  />
                </div>
              </div>

              {/* Last Name and First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentGroupUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentGroupUuid].id}
                    name={inputs[FIELD.equipmentGroupUuid].name}
                    label={inputs[FIELD.equipmentGroupUuid].label}
                    value={values[inputs[FIELD.equipmentGroupUuid].name]}
                    mandatory={inputs[FIELD.equipmentGroupUuid].mandatory}
                    ddOptions={ddOptionsEquipmentGroup}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.description].id}>
                  <FormInput
                    key={inputs[FIELD.description].id}
                    {...inputs[FIELD.description]}
                    value={values[inputs[FIELD.description].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.iscirRegistrationNumber].id}>
                  <FormInput
                    key={inputs[FIELD.iscirRegistrationNumber].id}
                    {...inputs[FIELD.iscirRegistrationNumber]}
                    value={values[inputs[FIELD.iscirRegistrationNumber].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.model].id}>
                  <FormInput key={inputs[FIELD.model].id} {...inputs[FIELD.model]} value={values[inputs[FIELD.model].name]} onChange={onChange} />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.manufacturingSeries].id}>
                  <FormInput
                    key={inputs[FIELD.manufacturingSeries].id}
                    {...inputs[FIELD.manufacturingSeries]}
                    value={values[inputs[FIELD.manufacturingSeries].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.manufacturedBy].id}>
                  <FormInput
                    key={inputs[FIELD.manufacturedBy].id}
                    {...inputs[FIELD.manufacturedBy]}
                    value={values[inputs[FIELD.manufacturedBy].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.manufacturingCountryUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.manufacturingCountryUuid].id}
                    name={inputs[FIELD.manufacturingCountryUuid].name}
                    label={inputs[FIELD.manufacturingCountryUuid].label}
                    value={values[inputs[FIELD.manufacturingCountryUuid].name]}
                    ddOptions={ddOptionsCountry}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.manufacturingYear].id}>
                  <FormInput
                    key={inputs[FIELD.manufacturingYear].id}
                    {...inputs[FIELD.manufacturingYear]}
                    value={values[inputs[FIELD.manufacturingYear].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii specificații principale instalație sub presiune (…)">
              {params.map((param, index) => (
                <div class="row">
                  <div class="column">
                    <FormInput key={index + 1 + 1000} name={"typeValue" + index} value={param.value} type="text" label={"tip parametru"} />
                  </div>
                  <div class="column">
                    <FormInput
                      key={index + 1 + 2000}
                      name={"value" + index}
                      value={values["value" + index]}
                      type="text"
                      label={"valoare parametru"}
                      onChange={onChange}
                    />
                  </div>
                </div>
              ))}
            </AccordionTab>
            <AccordionTab header="Detalii supapă de siguranţă)">
              {noOfValves.map((valve, index) => (
                <>
                  <div class="row">
                    <div class="column">
                      <FormInput
                        key={index + 3001}
                        name={"type" + index}
                        value={values["type" + index]}
                        type="text"
                        label={"Tip supapă"}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column">
                      <FormInput
                        key={index + 4001}
                        name={"series" + index}
                        value={values["series" + index]}
                        type="text"
                        label={"Numar de serie supapa"}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <FormInput
                        key={index + 5001}
                        name={"verificationNumber" + index}
                        value={values["verificationNumber" + index]}
                        type="text"
                        label={"Numar buletin verificare supapa"}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column">
                      <FormInput
                        key={index + 6001}
                        name={"verificationDate" + index}
                        value={values["verificationDate" + index]}
                        type="date"
                        label={"Data buletin verificare supapa"}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <FormInput
                        key={index + 7001}
                        name={"pressure" + index}
                        value={values["pressure" + index]}
                        type="text"
                        label={"Presiune reglare supapa"}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column">
                      <FormInput
                        key={index + 8001}
                        name={"company" + index}
                        value={values["company" + index]}
                        type="text"
                        label={"Firma care a emis buletinul de verificare"}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </>
              ))}
              <div class="row">
                <div class="column">
                  <img
                    class="imgtoolbar"
                    src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAddValve)}
                    alt="Adauga supapa (maxim 5)"
                    title="Adauga supapa (maxim 5)"
                    onClick={addValve}
                  />
                </div>
                <div class="column">&nbsp;</div>
              </div>
            </AccordionTab>

            {"0eb3eb12-6f5f-11ee-9c97-902b346c1788" !== values.registrationUuid ? (
              <AccordionTab header="Detalii autorizație instalație sub presiune">
                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.inspectionNumber].id}>
                    <FormInput
                      key={inputs[FIELD.inspectionNumber].id}
                      {...inputs[FIELD.inspectionNumber]}
                      value={values[inputs[FIELD.inspectionNumber].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.inspectionDate].id}>
                    <FormInput
                      key={inputs[FIELD.inspectionDate].id}
                      {...inputs[FIELD.inspectionDate]}
                      value={values[inputs[FIELD.inspectionDate].name]}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.inspectionName].id}>
                    <FormInput
                      key={inputs[FIELD.inspectionName].id}
                      {...inputs[FIELD.inspectionName]}
                      value={values[inputs[FIELD.inspectionName].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.inspectionCompany].id}>
                    <FormInput
                      key={inputs[FIELD.inspectionCompany].id}
                      {...inputs[FIELD.inspectionCompany]}
                      value={values[inputs[FIELD.inspectionCompany].name]}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.interiorInspectionDueDate].id}>
                    <FormInput
                      key={inputs[FIELD.interiorInspectionDueDate].id}
                      {...inputs[FIELD.interiorInspectionDueDate]}
                      value={values[inputs[FIELD.interiorInspectionDueDate].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.pressureInspectionDueDate].id}>
                    <FormInput
                      key={inputs[FIELD.pressureInspectionDueDate].id}
                      {...inputs[FIELD.pressureInspectionDueDate]}
                      value={values[inputs[FIELD.pressureInspectionDueDate].name]}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column">
                    Adaugă poză/pdf PV inspectie: <input type="file" class="fileI" onChange={(e) => setFileInspection(e.target.files[0])} />
                  </div>
                  <div class="column">&nbsp;</div>
                </div>
              </AccordionTab>
            ) : null}

            {"0eb3eb12-6f5f-11ee-9c97-902b346c1788" === values.registrationUuid ? (
              <AccordionTab header="Detalii autorizare echipament sub presiune intern">
                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionReportNumber].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionReportNumber].id}
                      {...inputs[FIELD.internInspectionReportNumber]}
                      value={values[inputs[FIELD.internInspectionReportNumber].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionReportDate].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionReportDate].id}
                      {...inputs[FIELD.internInspectionReportDate]}
                      value={values[inputs[FIELD.internInspectionReportDate].name]}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionRVTName].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionRVTName].id}
                      {...inputs[FIELD.internInspectionRVTName]}
                      value={values[inputs[FIELD.internInspectionRVTName].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionCompany].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionCompany].id}
                      {...inputs[FIELD.internInspectionCompany]}
                      value={values[inputs[FIELD.internInspectionCompany].name]}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionPVNumber].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionPVNumber].id}
                      {...inputs[FIELD.internInspectionPVNumber]}
                      value={values[inputs[FIELD.internInspectionPVNumber].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionPVDate].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionPVDate].id}
                      {...inputs[FIELD.internInspectionPVDate]}
                      value={values[inputs[FIELD.internInspectionPVDate].name]}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="column" id={"internalLink" + inputs[FIELD.internInspectionDueDate].id}>
                    <FormInput
                      key={inputs[FIELD.internInspectionDueDate].id}
                      {...inputs[FIELD.internInspectionDueDate]}
                      value={values[inputs[FIELD.internInspectionDueDate].name]}
                      onChange={onChange}
                    />
                  </div>
                  <div class="column">
                    <p>&nbsp;</p>
                  </div>
                </div>

                <div class="row">
                  <div class="column">
                    Adaugă poză/pdf PV inspectie: <input type="file" class="fileI" onChange={(e) => setFileInspection(e.target.files[0])} />
                  </div>
                  <div class="column">&nbsp;</div>
                </div>
              </AccordionTab>
            ) : null}

            <AccordionTab header="Detalii suplimentare echipament de ridicat">
              <Accordion multiple activeIndex={[0]}>
                <AccordionTab header="Raport verificari, probe si incercari (Anexa 2)">
                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.probeCasaNumber].id}>
                      <FormInput
                        key={inputs[FIELD.probeCasaNumber].id}
                        {...inputs[FIELD.probeCasaNumber]}
                        value={values[inputs[FIELD.probeCasaNumber].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.probeCasaDate].id}>
                      <FormInput
                        key={inputs[FIELD.probeCasaDate].id}
                        {...inputs[FIELD.probeCasaDate]}
                        value={values[inputs[FIELD.probeCasaDate].name]}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.probeCasaCompanyUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.probeCasaCompanyUuid].id}
                        name={inputs[FIELD.probeCasaCompanyUuid].name}
                        label={inputs[FIELD.probeCasaCompanyUuid].label}
                        value={values[inputs[FIELD.probeCasaCompanyUuid].name]}
                        ddOptions={allCompanies}
                        onChange={onChangeAll}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.probeCasaPersonUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.probeCasaPersonUuid].id}
                        name={inputs[FIELD.probeCasaPersonUuid].name}
                        label={inputs[FIELD.probeCasaPersonUuid].label}
                        value={values[inputs[FIELD.probeCasaPersonUuid].name]}
                        ddOptions={allPersons}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="Raport lucrări întreținere">
                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.maintenanceReportNumber].id}>
                      <FormInput
                        key={inputs[FIELD.maintenanceReportNumber].id}
                        {...inputs[FIELD.maintenanceReportNumber]}
                        value={values[inputs[FIELD.maintenanceReportNumber].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.maintenanceReportDate].id}>
                      <FormInput
                        key={inputs[FIELD.maintenanceReportDate].id}
                        {...inputs[FIELD.maintenanceReportDate]}
                        value={values[inputs[FIELD.maintenanceReportDate].name]}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.maintenanceReportCompanyUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.maintenanceReportCompanyUuid].id}
                        name={inputs[FIELD.maintenanceReportCompanyUuid].name}
                        label={inputs[FIELD.maintenanceReportCompanyUuid].label}
                        value={values[inputs[FIELD.maintenanceReportCompanyUuid].name]}
                        ddOptions={maintenanceCompanies}
                        onChange={onChangeMaintenance2}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.maintenanceReportPersonUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.maintenanceReportPersonUuid].id}
                        name={inputs[FIELD.maintenanceReportPersonUuid].name}
                        label={inputs[FIELD.maintenanceReportPersonUuid].label}
                        value={values[inputs[FIELD.maintenanceReportPersonUuid].name]}
                        ddOptions={maintenancePersons2}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="Raport lucrări reparare">
                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.fixingReportNumber].id}>
                      <FormInput
                        key={inputs[FIELD.fixingReportNumber].id}
                        {...inputs[FIELD.fixingReportNumber]}
                        value={values[inputs[FIELD.fixingReportNumber].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.fixingReportDate].id}>
                      <FormInput
                        key={inputs[FIELD.fixingReportDate].id}
                        {...inputs[FIELD.fixingReportDate]}
                        value={values[inputs[FIELD.fixingReportDate].name]}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.fixingReportCompanyUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.fixingReportCompanyUuid].id}
                        name={inputs[FIELD.fixingReportCompanyUuid].name}
                        label={inputs[FIELD.fixingReportCompanyUuid].label}
                        value={values[inputs[FIELD.fixingReportCompanyUuid].name]}
                        ddOptions={repairCompanies}
                        onChange={onChangeRepair2}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.fixingReportPersonUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.fixingReportPersonUuid].id}
                        name={inputs[FIELD.fixingReportPersonUuid].name}
                        label={inputs[FIELD.fixingReportPersonUuid].label}
                        value={values[inputs[FIELD.fixingReportPersonUuid].name]}
                        ddOptions={repairPersons2}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </AccordionTab>

            <AccordionTab header="EXPERTIZA TEHNICA ECHIPAMENT SUB PRESIUNE (OPTIONAL)">
              <Accordion multiple activeIndex={[0]}>
                <AccordionTab header="Proces verbal de verificare tehnica ISCIR">
                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyPVNumber].id}>
                      <FormInput
                        key={inputs[FIELD.verifyPVNumber].id}
                        {...inputs[FIELD.verifyPVNumber]}
                        value={values[inputs[FIELD.verifyPVNumber].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyPVDate].id}>
                      <FormInput
                        key={inputs[FIELD.verifyPVDate].id}
                        {...inputs[FIELD.verifyPVDate]}
                        value={values[inputs[FIELD.verifyPVDate].name]}
                        onChange={onChange}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyInspectorName].id}>
                      <FormInput
                        key={inputs[FIELD.verifyInspectorName].id}
                        {...inputs[FIELD.verifyInspectorName]}
                        value={values[inputs[FIELD.verifyInspectorName].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column">&nbsp;</div>
                  </div>

                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyExtendYears].id}>
                      <FormInput
                        key={inputs[FIELD.verifyExtendYears].id}
                        {...inputs[FIELD.verifyExtendYears]}
                        value={values[inputs[FIELD.verifyExtendYears].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyExtendDate].id}>
                      <FormInput
                        key={inputs[FIELD.verifyExtendDate].id}
                        {...inputs[FIELD.verifyExtendDate]}
                        value={values[inputs[FIELD.verifyExtendDate].name]}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="Raport tehnic expertiza tehnica">
                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyReportNumber].id}>
                      <FormInput
                        key={inputs[FIELD.verifyReportNumber].id}
                        {...inputs[FIELD.verifyReportNumber]}
                        value={values[inputs[FIELD.verifyReportNumber].name]}
                        onChange={onChange}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyReportDate].id}>
                      <FormInput
                        key={inputs[FIELD.verifyReportDate].id}
                        {...inputs[FIELD.verifyReportDate]}
                        value={values[inputs[FIELD.verifyReportDate].name]}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="Detalii firma care a efectuat expertiza tehnica">
                  <div class="row">
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyIscirCompanyUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.verifyIscirCompanyUuid].id}
                        name={inputs[FIELD.verifyIscirCompanyUuid].name}
                        label={inputs[FIELD.verifyIscirCompanyUuid].label}
                        value={values[inputs[FIELD.verifyIscirCompanyUuid].name]}
                        ddOptions={verifyCompanies}
                        onChange={onChangeVerify}
                      />
                    </div>
                    <div class="column" id={"internalLink" + inputs[FIELD.verifyIscirPersonUuid].id}>
                      <FormSelect
                        key={inputs[FIELD.verifyIscirPersonUuid].id}
                        name={inputs[FIELD.verifyIscirPersonUuid].name}
                        label={inputs[FIELD.verifyIscirPersonUuid].label}
                        value={values[inputs[FIELD.verifyIscirPersonUuid].name]}
                        ddOptions={verifyPersons}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </AccordionTab>

            <AccordionTab header="Detalii firme iscir (opțional)">
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.assembleCompanyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.assembleCompanyUuid].id}
                    name={inputs[FIELD.assembleCompanyUuid].name}
                    label={inputs[FIELD.assembleCompanyUuid].label}
                    value={values[inputs[FIELD.assembleCompanyUuid].name]}
                    ddOptions={assemblyCompanies}
                    onChange={onChangeAssembly}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.assemblePersonUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.assemblePersonUuid].id}
                    name={inputs[FIELD.assemblePersonUuid].name}
                    label={inputs[FIELD.assemblePersonUuid].label}
                    value={values[inputs[FIELD.assemblePersonUuid].name]}
                    ddOptions={assemblyPersons}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.maintenanceCompanyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.maintenanceCompanyUuid].id}
                    name={inputs[FIELD.maintenanceCompanyUuid].name}
                    label={inputs[FIELD.maintenanceCompanyUuid].label}
                    value={values[inputs[FIELD.maintenanceCompanyUuid].name]}
                    ddOptions={maintenanceCompanies}
                    onChange={onChangeMaintenance}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.maintenancePersonUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.maintenancePersonUuid].id}
                    name={inputs[FIELD.maintenancePersonUuid].name}
                    label={inputs[FIELD.maintenancePersonUuid].label}
                    value={values[inputs[FIELD.maintenancePersonUuid].name]}
                    ddOptions={maintenancePersons}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.fixingCompanyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.fixingCompanyUuid].id}
                    name={inputs[FIELD.fixingCompanyUuid].name}
                    label={inputs[FIELD.fixingCompanyUuid].label}
                    value={values[inputs[FIELD.fixingCompanyUuid].name]}
                    ddOptions={repairCompanies}
                    onChange={onChangeRepair}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.fixingPersonUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.fixingPersonUuid].id}
                    name={inputs[FIELD.fixingPersonUuid].name}
                    label={inputs[FIELD.fixingPersonUuid].label}
                    value={values[inputs[FIELD.fixingPersonUuid].name]}
                    ddOptions={repairPersons}
                    onChange={onChange}
                  />
                </div>
              </div>
            </AccordionTab>

            <AccordionTab header="Detalii firmă deținătoare - echipament închiriat (opțional)">
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.ownerCompanyName].id}>
                  <FormInput
                    key={inputs[FIELD.ownerCompanyName].id}
                    {...inputs[FIELD.ownerCompanyName]}
                    value={values[inputs[FIELD.ownerCompanyName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.ownerCompanyCountyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.ownerCompanyCountyUuid].id}
                    name={inputs[FIELD.ownerCompanyCountyUuid].name}
                    label={inputs[FIELD.ownerCompanyCountyUuid].label}
                    value={values[inputs[FIELD.ownerCompanyCountyUuid].name]}
                    ddOptions={ddOptionsCounty}
                    onChange={onChangeCounty}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.ownerCompanyTownUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.ownerCompanyTownUuid].id}
                    name={inputs[FIELD.ownerCompanyTownUuid].name}
                    label={inputs[FIELD.ownerCompanyTownUuid].label}
                    value={values[inputs[FIELD.ownerCompanyTownUuid].name]}
                    ddOptions={ddOptionsTown}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.ownerRslLastName].id}>
                  <FormInput
                    key={inputs[FIELD.ownerRslLastName].id}
                    {...inputs[FIELD.ownerRslLastName]}
                    value={values[inputs[FIELD.ownerRslLastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.ownerRslFirstName].id}>
                  <FormInput
                    key={inputs[FIELD.ownerRslFirstName].id}
                    {...inputs[FIELD.ownerRslFirstName]}
                    value={values[inputs[FIELD.ownerRslFirstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.ownerRslPhone].id}>
                  <FormInput
                    key={inputs[FIELD.ownerRslPhone].id}
                    {...inputs[FIELD.ownerRslPhone]}
                    value={values[inputs[FIELD.ownerRslPhone].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.ownerRslEmail].id}>
                  <FormInput
                    key={inputs[FIELD.ownerRslEmail].id}
                    {...inputs[FIELD.ownerRslEmail]}
                    value={values[inputs[FIELD.ownerRslEmail].name]}
                    onChange={onChange}
                  />
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        {/* Submit Buttons */}
        {processingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.EquipmentPressureSave)}
                alt="Salvează echipament"
                title="Salvează echipament"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
        {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      </form>
      <div class="spaceDiv" />
      <Link to={"/equipment/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentPressureList)} alt="Listă echipamente" title="Listă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/addlifting"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingAdd)}
          alt="Adaugă echipament de ridicat"
          title="Adaugă echipament de ridicat"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentPressureAddDisabled)}
        alt="Adaugă echipament sub presiune"
        title="Adaugă echipament sub presiune"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.EquipmentPressureImport)} alt="Importă echipamente" title="Importă echipamente" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingExportDisabled)}
        alt="Exportă echipamente"
        title="Exportă echipamente"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listscrap"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentScrapList)}
          alt="Listă echipamente casate"
          title="Listă echipamente casate"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/equipment/listtransfer"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.EquipmentTransferList)}
          alt="Listă echipamente transferate"
          title="Listă echipamente transferate"
        />
      </Link>
      <div class="spaceDiv" />
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
