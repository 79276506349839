import React from "react";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Firmă",
    path: "/#",
    iconClosed: <RiIcons.RiArrowDownFill />,
    iconOpened: <RiIcons.RiArrowUpFill />,
    subNav: [
      {
        title: "Firme",
        path: "/company/list",
      },
      {
        title: "Puncte de lucru",
        path: "/companyBranch/list",
      },
      {
        title: "Deservenți",
        path: "/operator/list",
      },
      {
        title: "Deservenți auxiliari",
        path: "/worker/list",
      },
      {
        title: "Echipamente",
        path: "/equipment/list",
      },
      {
        title: "Documente",
        path: "/company/sessions",
      },
      {
        title: "Personal",
        path: "/person/list",
      },
      {
        title: "Rapoarte",
        path: "/report/reports",
      },
      {
        title: "Firme Iscir",
        path: "/iscircompany/list",
      },
      {
        title: "Sumar",
        path: "/client/statistics",
      },
    ],
  },
  {
    title: "Admin",
    path: "/#",
    iconClosed: <RiIcons.RiArrowDownFill />,
    iconOpened: <RiIcons.RiArrowUpFill />,
    subNav: [
      {
        title: "Țări",
        path: "/country/list",
      },
      {
        title: "Județe",
        path: "/county/list",
      },
      {
        title: "Orașe",
        path: "/town/list",
      },
    ],
  },
  {
    title: "Diverse",
    path: "/#",
    iconClosed: <RiIcons.RiArrowDownFill />,
    iconOpened: <RiIcons.RiArrowUpFill />,
    subNav: [
      {
        title: "Setări",
        path: "/overview/users",
      },
      {
        title: "Deconectare",
        path: "/public/logout",
      },
    ],
  },
];
