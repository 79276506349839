import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { STRING_CONST } from "../common/globals";

const FormSelect = (props) => {
  const { label, onChange, name, ddOptions, value, mandatory } = props;

  return (
    <div className="formInput">
      {STRING_CONST.MANDATORY_FIELD === mandatory ? <label className="labelred">{label}</label> : ""}
      {STRING_CONST.MANDATORY_FIELD_IN_GROUP === mandatory ? <label className="labelorange">{label}</label> : ""}
      {STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP === mandatory ? <label className="labelbrown">{label}</label> : ""}
      {STRING_CONST.MANDATORY_FIELD !== mandatory &&
      STRING_CONST.MANDATORY_FIELD_IN_GROUP !== mandatory &&
      STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP !== mandatory ? (
        <label>{label}</label>
      ) : (
        ""
      )}
      <Select name={name} value={value} label={label} onChange={onChange}>
        {ddOptions.map((ddOption) => (
          <MenuItem key={ddOption.uuid} value={ddOption.uuid}>
            {ddOption.value}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default FormSelect;
