import "../../static/css/pageLayout.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, STRING_CONST, dialogFooterContent } from "../../common/globals";
import { FIELD_SIZE, isEmptyString, validateString, validateMinMaxText } from "../../common/validations";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import FormSelectMultiple from "../../components/FormSelectMultiple";
import ProcessFormErrors from "../../components/ProcessFormErrors";

export default function RegisterOperator() {
  const baseURL = BASE_URL + "operator/add";
  const companyListURL = BASE_URL + "company/listsimplecompaniesandbranches";
  const prescriptionsLiftingURL = BASE_URL + "structure/operator/prescriptions/lifting";
  const prescriptionsPressureURL = BASE_URL + "structure/operator/prescriptions/pressure";
  const operatorTypeURL = BASE_URL + "structure/operator/types";
  const structureChildrenURL = BASE_URL + "structure/worker/children";
  const uploadRegistrationURL = BASE_URL + "operator/registration/upload";
  const uploadRegistrationAndPermitURL = BASE_URL + "operator/registrationpermit/upload";
  const uploadPermitURL = BASE_URL + "operator/permit/upload";

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [operatorTypes, setOperatorTypes] = useState([]);
  const [equipmentClassifications, setEquipmentClassifications] = useState([]);
  const [equipmentClasses, setEquipmentClasses] = useState([]);
  const [equipmentGroups, setEquipmentGroups] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [fileRegistration, setFileRegistration] = useState(null);
  const [filePermit, setFilePermit] = useState(null);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const equipmentTypes = [
    {
      uuid: 1,
      value: "Instalaţii de ridicat",
    },
    {
      uuid: 2,
      value: "Instalaţii sub presiune",
    },
  ];

  const { auth } = useAuth();

  const [values, setValues] = useState({
    lastName: "",
    firstName: "",
    identifier: "",
    phone: "",
    email: "",
    operatorTypeUuid: "",
    equipmentClassificationUuid: "",
    equipmentClassUuid: "",
    equipmentGroupUuid: [],
    permitNumber: "",
    permitIssuedDate: "",
    permitVisaDate: "",
    registrationNumber: "",
    registrationIssuedDate: "",
    companyUuid: "",
    equipmentType: "",
    prescriptionUuid: "",
  });

  const inputs = [
    {
      id: 0,
      name: "lastName",
      type: "text",
      placeholder: "Nume familie deservent",
      label: "Nume familie deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Prenume deservent",
      label: "Prenume deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 2,
      name: "identifier",
      type: "text",
      placeholder: "CNP deservent",
      label: "CNP deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 3,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon mobil deservent",
      label: "Număr telefon mobil deservent",
    },
    {
      id: 4,
      name: "email",
      type: "text",
      placeholder: "Adresă e-mail deservent",
      label: "Adresă e-mail deservent",
    },
    {
      id: 5,
      name: "operatorTypeUuid",
      type: "selectClass",
      placeholder: "Tip deservent",
      label: "Tip deservent",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 6,
      name: "equipmentClassificationUuid",
      type: "selectClass",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 7,
      name: "equipmentClassUuid",
      type: "selectClass",
      placeholder: "Clasă echipament (dacă este cazul)",
      label: "Clasă echipament (dacă este cazul)",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 8,
      name: "equipmentGroupUuid",
      type: "selectClass",
      placeholder: "Grupă echipament (dacă este cazul)",
      label: "Grupă echipament (dacă este cazul)",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 9,
      name: "registrationNumber",
      type: "text",
      placeholder: "Număr autorizație",
      label: "Număr autorizație",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 10,
      name: "registrationIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizație",
      label: "Dată eliberare autorizație",
    },
    {
      id: 11,
      name: "permitNumber",
      type: "text",
      placeholder: "Număr talon",
      label: "Număr talon",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 12,
      name: "permitIssuedDate",
      type: "date",
      placeholder: "Dată eliberare talon",
      label: "Dată eliberare talon",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 13,
      name: "permitVisaDate",
      type: "date",
      placeholder: "Dată ultima viză valabilă",
      label: "Dată ultima viză valabilă",
      mandatory: STRING_CONST.OPTIONAL_MANDATORY_ALL_GROUP,
    },
    {
      id: 14,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 15,
      name: "equipmentType",
      type: "selectClass",
      placeholder: "Tip prescripţie",
      label: "Tip prescripţie",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
    {
      id: 16,
      name: "prescriptionUuid",
      type: "selectClass",
      placeholder: "Prescripţie",
      label: "Prescripţie",
      mandatory: STRING_CONST.MANDATORY_FIELD,
    },
  ];

  const FIELD = {
    lastName: 0,
    firstName: 1,
    identifier: 2,
    phone: 3,
    email: 4,
    operatorTypeUuid: 5,
    equipmentClassificationUuid: 6,
    equipmentClassUuid: 7,
    equipmentGroupUuid: 8,
    registrationNumber: 9,
    registrationIssuedDate: 10,
    permitNumber: 11,
    permitIssuedDate: 12,
    permitVisaDate: 13,
    companyUuid: 14,
    equipmentType: 15,
    prescriptionUuid: 16,
  };

  // load configs
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    if (!validateForm()) {
      setProcessingDialog(false);
      return;
    }
    axios
      .post(
        baseURL,
        {
          companyUuid: values.companyUuid,
          lastName: values.lastName,
          firstName: values.firstName,
          identifier: values.identifier,
          phone: values.phone,
          email: values.email,
          operatorTypeUuid: values.operatorTypeUuid,
          equipmentClassificationUuid: values.equipmentClassificationUuid,
          equipmentClassUuid: values.equipmentClassUuid,
          equipmentGroupUuid: values.equipmentGroupUuid,
          permitNumber: values.permitNumber,
          permitIssuedDate: values.permitIssuedDate,
          permitVisaDate: values.permitVisaDate,
          registrationNumber: values.registrationNumber,
          registrationIssuedDate: values.registrationIssuedDate,
          prescriptionUuid: values.prescriptionUuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        uploadDocuments(res.data.registrationUuid, res.data.permitUuid);
        let file = document.querySelector(".fileR");
        if (file !== null) {
          file.value = "";
        }
        file = document.querySelector(".fileP");
        if (file !== null) {
          file.value = "";
        }
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const validateForm = () => {
    let result = true;
    let data = [];
    if (isEmptyString(values.lastName)) {
      data.push({ id: inputs[FIELD.lastName].id, value: inputs[FIELD.lastName].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.lastName, FIELD_SIZE.LastNameMin, FIELD_SIZE.LastNameMax)) {
      data.push({
        id: inputs[FIELD.lastName].id,
        value: inputs[FIELD.lastName].label + validateMinMaxText(FIELD_SIZE.LastNameMin, FIELD_SIZE.LastNameMax),
      });
      result = false;
    }
    if (isEmptyString(values.firstName)) {
      data.push({ id: inputs[FIELD.firstName].id, value: inputs[FIELD.firstName].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.firstName, FIELD_SIZE.FirstNameMin, FIELD_SIZE.FirstNameMax)) {
      data.push({
        id: inputs[FIELD.firstName].id,
        value: inputs[FIELD.firstName].label + validateMinMaxText(FIELD_SIZE.FirstNameMin, FIELD_SIZE.FirstNameMax),
      });
      result = false;
    }
    if (isEmptyString(values.companyUuid)) {
      data.push({ id: inputs[FIELD.companyUuid].id, value: inputs[FIELD.companyUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    }
    if (isEmptyString(values.identifier)) {
      data.push({ id: inputs[FIELD.identifier].id, value: inputs[FIELD.identifier].label + STRING_CONST.VALIDATION_IS_MANDATORY });
      result = false;
    } else if (!validateString(values.identifier, FIELD_SIZE.Zero, FIELD_SIZE.IdentifierMax)) {
      data.push({
        id: inputs[FIELD.identifier].id,
        value: inputs[FIELD.identifier].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.IdentifierMax),
      });
      result = false;
    }
    if (!validateString(values.phone, FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax)) {
      data.push({ id: inputs[FIELD.phone].id, value: inputs[FIELD.phone].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.PhoneMax) });
      result = false;
    }
    if (!validateString(values.email, FIELD_SIZE.Zero, FIELD_SIZE.EmailMax)) {
      data.push({ id: inputs[FIELD.email].id, value: inputs[FIELD.email].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.EmailMax) });
      result = false;
    }
    if (isEmptyString(values.equipmentType)) {
      data.push({
        id: inputs[FIELD.equipmentType].id,
        value: inputs[FIELD.equipmentType].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (isEmptyString(values.prescriptionUuid)) {
      data.push({
        id: inputs[FIELD.prescriptionUuid].id,
        value: inputs[FIELD.prescriptionUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (isEmptyString(values.operatorTypeUuid)) {
      data.push({
        id: inputs[FIELD.operatorTypeUuid].id,
        value: inputs[FIELD.operatorTypeUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (isEmptyString(values.equipmentClassificationUuid)) {
      data.push({
        id: inputs[FIELD.equipmentClassificationUuid].id,
        value: inputs[FIELD.equipmentClassificationUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (isEmptyString(values.equipmentClassUuid) && null !== equipmentClasses && equipmentClasses.length > 0) {
      data.push({
        id: inputs[FIELD.equipmentClassUuid].id,
        value: inputs[FIELD.equipmentClassUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (isEmptyString(values.equipmentGroupUuid) && null !== equipmentGroups && equipmentGroups.length > 0) {
      data.push({
        id: inputs[FIELD.equipmentGroupUuid].id,
        value: inputs[FIELD.equipmentGroupUuid].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (isEmptyString(values.registrationNumber)) {
      data.push({
        id: inputs[FIELD.registrationNumber].id,
        value: inputs[FIELD.registrationNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_REGISTRATION,
      });
      result = false;
    }
    if (!validateString(values.registrationNumber, FIELD_SIZE.Zero, FIELD_SIZE.OperatorRegistrationNumberMax)) {
      data.push({
        id: inputs[FIELD.registrationNumber].id,
        value: inputs[FIELD.registrationNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.OperatorRegistrationNumberMax),
      });
      result = false;
    }
    if (!isEmptyString(values.permitNumber) || !isEmptyString(values.permitIssuedDate) || !isEmptyString(values.permitVisaDate)) {
      if (isEmptyString(values.permitNumber)) {
        data.push({
          id: inputs[FIELD.permitNumber].id,
          value: inputs[FIELD.permitNumber].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT,
        });
        result = false;
      }
      if (isEmptyString(values.permitIssuedDate)) {
        data.push({
          id: inputs[FIELD.permitIssuedDate].id,
          value: inputs[FIELD.permitIssuedDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT,
        });
        result = false;
      }
      if (isEmptyString(values.permitVisaDate)) {
        data.push({
          id: inputs[FIELD.permitVisaDate].id,
          value: inputs[FIELD.permitVisaDate].label + STRING_CONST.VALIDATION_IS_MANDATORY_FOR_OPERATOR_PERMIT,
        });
        result = false;
      }
    }
    if (!validateString(values.permitNumber, FIELD_SIZE.Zero, FIELD_SIZE.OperatorPermitNumberMax)) {
      data.push({
        id: inputs[FIELD.permitNumber].id,
        value: inputs[FIELD.permitNumber].label + validateMinMaxText(FIELD_SIZE.Zero, FIELD_SIZE.OperatorPermitNumberMax),
      });
      result = false;
    }
    setValidationErrors(data);
    return result;
  };

  const uploadDocuments = (registrationUuid, permitUuid) => {
    if (fileRegistration === null && filePermit === null) {
      setProcessingDialog(false);
      setProcessedDialog(true);
      return;
    }
    let URL = "";
    const formData = new FormData();
    if (fileRegistration === null && filePermit !== null) {
      URL = uploadPermitURL;
      formData.append("permitUuid", permitUuid);
      formData.append("filePermit", filePermit);
    }
    if (fileRegistration !== null && filePermit === null) {
      URL = uploadRegistrationURL;
      formData.append("registrationUuid", registrationUuid);
      formData.append("fileRegistration", fileRegistration);
    }
    if (fileRegistration !== null && filePermit !== null) {
      URL = uploadRegistrationAndPermitURL;
      formData.append("registrationUuid", registrationUuid);
      formData.append("fileRegistration", fileRegistration);
      formData.append("permitUuid", permitUuid);
      formData.append("filePermit", filePermit);
    }
    axios
      .post(URL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        setProcessingDialog(false);
        setProcessedDialog(true);
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeEquipmentType = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      prescriptionUuid: "",
      operatorTypeUuid: "",
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setPrescriptions([]);
    setOperatorTypes([]);
    setEquipmentClassifications([]);
    setEquipmentClasses([]);
    setEquipmentGroups([]);
    let URL = prescriptionsLiftingURL;
    if (2 === e.target.value) {
      URL = prescriptionsPressureURL;
    }
    axios
      .post(
        URL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setPrescriptions(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangePrescription = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      operatorTypeUuid: "",
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setOperatorTypes([]);
    setEquipmentClassifications([]);
    setEquipmentClasses([]);
    setEquipmentGroups([]);
    axios
      .post(
        operatorTypeURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setOperatorTypes(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeOperatorType = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setEquipmentClassifications([]);
    setEquipmentClasses([]);
    setEquipmentGroups([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setEquipmentClassifications(response.data.values);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeEquipmentClassification = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassUuid: "", equipmentGroupUuid: [] });
    setEquipmentClasses([]);
    setEquipmentGroups([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (response.data.type === "equipment class") {
          setEquipmentClasses(response.data.values);
        } else if (response.data.type === "equipment group") {
          setEquipmentGroups(response.data.values);
        }
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeEquipmentClass = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroupUuid: [] });
    setEquipmentGroups([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        if (response.data.type === "equipment group") {
          setEquipmentGroups(response.data.values);
        }
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangeEquipmentGroup = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button class="buttonClassGreen">Adaugă deservent</button>
      <div class="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.OperatorAddDisabled)} alt="Adaugă deservent" title="Adaugă deservent" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      <form class="formArea">
        <div class="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii înregistrare date personale deservent">
              {/* Last Name and First Name */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.lastName].id}>
                  <FormInput
                    key={inputs[FIELD.lastName].id}
                    {...inputs[FIELD.lastName]}
                    value={values[inputs[FIELD.lastName].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.firstName].id}>
                  <FormInput
                    key={inputs[FIELD.firstName].id}
                    {...inputs[FIELD.firstName]}
                    value={values[inputs[FIELD.firstName].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.companyUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.companyUuid].id}
                    name={inputs[FIELD.companyUuid].name}
                    label={inputs[FIELD.companyUuid].label}
                    value={values[inputs[FIELD.companyUuid].name]}
                    mandatory={inputs[FIELD.companyUuid].mandatory}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* CNP */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.identifier].id}>
                  <FormInput
                    key={inputs[FIELD.identifier].id}
                    {...inputs[FIELD.identifier]}
                    value={values[inputs[FIELD.identifier].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Phone and Email */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.phone].id}>
                  <FormInput key={inputs[FIELD.phone].id} {...inputs[FIELD.phone]} value={values[inputs[FIELD.phone].name]} onChange={onChange} />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.email].id}>
                  <FormInput key={inputs[FIELD.email].id} {...inputs[FIELD.email]} value={values[inputs[FIELD.email].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii tip autorizație deservent">
              {/* Operator Class */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentType].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentType].id}
                    name={inputs[FIELD.equipmentType].name}
                    label={inputs[FIELD.equipmentType].label}
                    value={values[inputs[FIELD.equipmentType].name]}
                    mandatory={inputs[FIELD.equipmentType].mandatory}
                    ddOptions={equipmentTypes}
                    onChange={onChangeEquipmentType}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.prescriptionUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.prescriptionUuid].id}
                    name={inputs[FIELD.prescriptionUuid].name}
                    label={inputs[FIELD.prescriptionUuid].label}
                    value={values[inputs[FIELD.prescriptionUuid].name]}
                    mandatory={inputs[FIELD.prescriptionUuid].mandatory}
                    ddOptions={prescriptions}
                    onChange={onChangePrescription}
                  />
                </div>
              </div>

              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.operatorTypeUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.operatorTypeUuid].id}
                    name={inputs[FIELD.operatorTypeUuid].name}
                    label={inputs[FIELD.operatorTypeUuid].label}
                    value={values[inputs[FIELD.operatorTypeUuid].name]}
                    mandatory={inputs[FIELD.operatorTypeUuid].mandatory}
                    ddOptions={operatorTypes}
                    onChange={onChangeOperatorType}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentClassificationUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassificationUuid].id}
                    name={inputs[FIELD.equipmentClassificationUuid].name}
                    label={inputs[FIELD.equipmentClassificationUuid].label}
                    value={values[inputs[FIELD.equipmentClassificationUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassificationUuid].mandatory}
                    ddOptions={equipmentClassifications}
                    onChange={onChangeEquipmentClassification}
                  />
                </div>
              </div>

              {/* Equipment Type and Equipment Group */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentClassUuid].id}>
                  <FormSelect
                    key={inputs[FIELD.equipmentClassUuid].id}
                    name={inputs[FIELD.equipmentClassUuid].name}
                    label={inputs[FIELD.equipmentClassUuid].label}
                    value={values[inputs[FIELD.equipmentClassUuid].name]}
                    mandatory={inputs[FIELD.equipmentClassUuid].mandatory}
                    ddOptions={equipmentClasses}
                    onChange={onChangeEquipmentClass}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.equipmentGroupUuid].id}>
                  <FormSelectMultiple
                    key={inputs[FIELD.equipmentGroupUuid].id}
                    name={inputs[FIELD.equipmentGroupUuid].name}
                    label={inputs[FIELD.equipmentGroupUuid].label}
                    value={values[inputs[FIELD.equipmentGroupUuid].name]}
                    mandatory={inputs[FIELD.equipmentGroupUuid].mandatory}
                    ddOptions={equipmentGroups}
                    onChange={onChangeEquipmentGroup}
                  />
                </div>
              </div>

              {/* Registration Number and Registration Issued Date */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.registrationNumber].id}>
                  <FormInput
                    key={inputs[FIELD.registrationNumber].id}
                    {...inputs[FIELD.registrationNumber]}
                    value={values[inputs[FIELD.registrationNumber].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.registrationIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.registrationIssuedDate].id}
                    {...inputs[FIELD.registrationIssuedDate]}
                    value={values[inputs[FIELD.registrationIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Permit Number and Permit Issued Date */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.permitNumber].id}>
                  <FormInput
                    key={inputs[FIELD.permitNumber].id}
                    {...inputs[FIELD.permitNumber]}
                    value={values[inputs[FIELD.permitNumber].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column" id={"internalLink" + inputs[FIELD.permitIssuedDate].id}>
                  <FormInput
                    key={inputs[FIELD.permitIssuedDate].id}
                    {...inputs[FIELD.permitIssuedDate]}
                    value={values[inputs[FIELD.permitIssuedDate].name]}
                    onChange={onChange}
                  />
                </div>
              </div>

              {/* Permit Visa Date */}
              <div class="row">
                <div class="column" id={"internalLink" + inputs[FIELD.permitVisaDate].id}>
                  <FormInput
                    key={inputs[FIELD.permitVisaDate].id}
                    {...inputs[FIELD.permitVisaDate]}
                    value={values[inputs[FIELD.permitVisaDate].name]}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf autorizaţie deservent: <input class="fileR" type="file" onChange={(e) => setFileRegistration(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf talon deservent: <input class="fileP" type="file" onChange={(e) => setFilePermit(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        {/* Submit Buttons */}
        {processingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.OperatorSave)}
                alt="Salvează deservent"
                title="Salvează deservent"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
        {validationErrors.length > 0 ? <ProcessFormErrors validationErrors={validationErrors} /> : null}
      </form>
      <div class="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.OperatorAddDisabled)} alt="Adaugă deservent" title="Adaugă deservent" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      <div class="spaceDiv" />
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
