import "../../static/css/pageLayout.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { BASE_URL, ICONS } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";
import FormSelectMultiple from "../../components/FormSelectMultiple";

export default function EditOperator() {
  const getOperatorURL = BASE_URL + "operator/get";
  const updateOperatorURL = BASE_URL + "operator/update";
  const companyListURL = BASE_URL + "company/listsimplecompaniesandbranches";
  const operatorTypesURL = BASE_URL + "structure/operator/types";
  const structureChildrenURL = BASE_URL + "structure/worker/children";
  const uploadRegistrationURL = BASE_URL + "operator/registration/upload";
  const uploadRegistrationAndPermitURL = BASE_URL + "operator/registrationpermit/upload";
  const uploadPermitURL = BASE_URL + "operator/permit/upload";

  const { auth } = useAuth();

  const [values, setValues] = useState({
    lastName: "",
    firstName: "",
    identifier: "",
    phone: "",
    email: "",
    operatorTypeUuid: "",
    equipmentClassificationUuid: "",
    equipmentClassUuid: "",
    equipmentGroupUuid: [],
    permitNumber: "",
    permitIssuedDate: "",
    permitVisaDate: "",
    registrationNumber: "",
    registrationIssuedDate: "",
    companyUuid: "",
  });

  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);

  const [operatorTypes, setOperatorTypes] = useState([]);
  const [equipmentClassifications, setEquipmentClassifications] = useState([]);
  const [equipmentClasses, setEquipmentClasses] = useState([]);
  const [equipmentGroups, setEquipmentGroups] = useState([]);

  const [fileRegistration, setFileRegistration] = useState(null);
  const [filePermit, setFilePermit] = useState(null);

  const inputs = [
    {
      id: 0,
      name: "lastName",
      type: "text",
      placeholder: "Nume familie deservent",
      label: "Nume familie deservent",
    },
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Prenume deservent",
      label: "Prenume deservent",
    },
    {
      id: 2,
      name: "identifier",
      type: "text",
      placeholder: "CNP deservent",
      label: "CNP deservent",
    },
    {
      id: 3,
      name: "phone",
      type: "text",
      placeholder: "Număr telefon mobil deservent",
      label: "Număr telefon mobil deservent",
    },
    {
      id: 4,
      name: "email",
      type: "text",
      placeholder: "Adresă e-mail deservent",
      label: "Adresă e-mail deservent",
    },
    {
      id: 5,
      name: "operatorTypeUuid",
      type: "selectClass",
      placeholder: "Tip deservent",
      label: "Tip deservent",
    },
    {
      id: 6,
      name: "equipmentClassificationUuid",
      type: "selectClass",
      placeholder: "Clasificare echipament",
      label: "Clasificare echipament",
    },
    {
      id: 7,
      name: "equipmentClassUuid",
      type: "selectClass",
      placeholder: "Clasă echipament (dacă este cazul)",
      label: "Clasă echipament (dacă este cazul)",
    },
    {
      id: 8,
      name: "equipmentGroupUuid",
      type: "selectClass",
      placeholder: "Grupă echipament (dacă este cazul)",
      label: "Grupă echipament (dacă este cazul)",
    },
    {
      id: 9,
      name: "registrationNumber",
      type: "text",
      placeholder: "Număr autorizație",
      label: "Număr autorizație",
    },
    {
      id: 10,
      name: "registrationIssuedDate",
      type: "date",
      placeholder: "Dată eliberare autorizație",
      label: "Dată eliberare autorizație",
    },
    {
      id: 11,
      name: "permitNumber",
      type: "text",
      placeholder: "Număr talon",
      label: "Număr talon",
    },
    {
      id: 12,
      name: "permitIssuedDate",
      type: "date",
      placeholder: "Dată eliberare talon",
      label: "Dată eliberare talon",
    },
    {
      id: 13,
      name: "permitVisaDate",
      type: "date",
      placeholder: "Dată ultima viză valabilă",
      label: "Dată ultima viză valabilă",
    },
    {
      id: 14,
      name: "companyUuid",
      type: "selectClass",
      placeholder: "Cod punct de lucru",
      label: "Cod punct de lucru",
    },
  ];

  // load operator data
  useEffect(() => {
    axios
      .post(
        getOperatorURL,
        { uuid: sessionStorage.getItem("editOperatorUuid") },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("cri");
        console.log(response);
        console.log(response.data);
        let data = [];
        data = { ...data, lastName: response.data.lastName !== null ? response.data.lastName : "" };
        data = { ...data, firstName: response.data.firstName !== null ? response.data.firstName : "" };
        data = { ...data, identifier: response.data.identifier !== null ? response.data.identifier : "" };
        data = { ...data, phone: response.data.phone !== null ? response.data.phone : "" };
        data = { ...data, email: response.data.email !== null ? response.data.email : "" };
        data = { ...data, permitNumber: response.data.permitNumber !== null ? response.data.permitNumber : "" };
        data = { ...data, permitIssuedDate: response.data.permitIssuedDate !== null ? response.data.permitIssuedDate : "" };
        data = { ...data, permitVisaDate: response.data.permitVisaDate !== null ? response.data.permitVisaDate : "" };
        data = { ...data, registrationNumber: response.data.registrationNumber !== null ? response.data.registrationNumber : "" };
        data = { ...data, registrationIssuedDate: response.data.registrationIssuedDate !== null ? response.data.registrationIssuedDate : "" };
        console.log("data");
        console.log(data);
        setValues({ ...values, ...data });
      })
      .catch((error) => {
        console.log("Error loading countries");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load companies
  useEffect(() => {
    axios
      .get(companyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCompany(response.data);
      })
      .catch((error) => {
        console.log("Error loading companies");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // load operator classes
  useEffect(() => {
    axios
      .post(
        operatorTypesURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setOperatorTypes(response.data);
      })
      .catch((error) => {
        console.log("Error loading operator classes");
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        updateOperatorURL,
        {
          companyUuid: values.companyUuid,
          lastName: values.lastName,
          firstName: values.firstName,
          identifier: values.identifier,
          phone: values.phone,
          email: values.email,
          operatorTypeUuid: values.operatorTypeUuid,
          equipmentClassificationUuid: values.equipmentClassificationUuid,
          equipmentClassUuid: values.equipmentClassUuid,
          equipmentGroupUuid: values.equipmentGroupUuid,
          permitNumber: values.permitNumber,
          permitIssuedDate: values.permitIssuedDate,
          permitVisaDate: values.permitVisaDate,
          registrationNumber: values.registrationNumber,
          registrationIssuedDate: values.registrationIssuedDate,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        console.log("Operator added");
        console.log(res.data);
        alert("Deservent adaugat");
        uploadDocuments(res.data.registrationUuid, res.data.permitUuid);
        let file = document.querySelector(".fileR");
        if (file !== null) {
          file.value = "";
        }
        file = document.querySelector(".fileP");
        if (file !== null) {
          file.value = "";
        }
      })
      .catch((error) => {
        console.log("Error adding operator");
        console.log(error);
        alert("Deserventul nu a putut fi adăugat");
      });
    // console.log("Submit");
  };

  const uploadDocuments = (registrationUuid, permitUuid) => {
    if (fileRegistration === null && filePermit === null) {
      return;
    }
    let URL = "";
    const formData = new FormData();
    if (fileRegistration === null && filePermit !== null) {
      URL = uploadPermitURL;
      formData.append("permitUuid", permitUuid);
      formData.append("filePermit", filePermit);
    }
    if (fileRegistration !== null && filePermit === null) {
      URL = uploadRegistrationURL;
      formData.append("registrationUuid", registrationUuid);
      formData.append("fileRegistration", fileRegistration);
    }
    if (fileRegistration !== null && filePermit !== null) {
      URL = uploadRegistrationAndPermitURL;
      formData.append("registrationUuid", registrationUuid);
      formData.append("fileRegistration", fileRegistration);
      formData.append("permitUuid", permitUuid);
      formData.append("filePermit", filePermit);
    }
    axios
      .post(URL, formData, {
        "content-type": "multipart/form-data",
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((res) => {
        console.log("Document uploaded");
        console.log(res.data);
        alert("Documente salvate");
      })
      .catch((error) => {
        console.log("Error uploading documents");
        console.log(error);
        alert("Documentele nu au putut fi salvate");
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeOperatorType = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      equipmentClassificationUuid: "",
      equipmentClassUuid: "",
      equipmentGroupUuid: [],
      equipmentTypeUuid: "",
    });
    setEquipmentClassifications([]);
    setEquipmentClasses([]);
    setEquipmentGroups([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Operator types loaded");
        setEquipmentClassifications(response.data.values);
      })
      .catch((error) => {
        console.log("Error loading operator types");
        console.log(error);
        alert("Tipurile de deserventi nu au putut fi citite");
      });
  };

  const onChangeEquipmentClassification = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value, equipmentClassUuid: "", equipmentGroupUuid: [] });
    setEquipmentClasses([]);
    setEquipmentGroups([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Operator types loaded");
        if (response.data.type === "equipment class") {
          setEquipmentClasses(response.data.values);
        } else if (response.data.type === "equipment group") {
          setEquipmentGroups(response.data.values);
        }
      })
      .catch((error) => {
        console.log("Error loading operator types");
        console.log(error);
        alert("Tipurile de deserventi nu au putut fi citite");
      });
  };

  const onChangeEquipmentClass = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value, equipmentGroupUuid: [] });
    setEquipmentGroups([]);
    axios
      .post(
        structureChildrenURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        console.log("Operator types loaded");
        if (response.data.type === "equipment group") {
          setEquipmentGroups(response.data.values);
        }
      })
      .catch((error) => {
        console.log("Error loading operator types");
        console.log(error);
        alert("Tipurile de deserventi nu au putut fi citite");
      });
  };

  const onChangeEquipmentGroup = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);
  console.log("render");

  return (
    <div className="clientArea">
      <Sidebar />
      {/* Navigation Buttons */}
      <button class="buttonClassGreen">Modifică deservent</button>
      <div class="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      <form class="formArea">
        <div class="rowdouble">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Detalii date personale deservent">
              {/* Last Name and First Name */}
              <div class="row">
                <div class="column">
                  <FormInput key={inputs[0].id} {...inputs[0]} value={values[inputs[0].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <FormInput key={inputs[1].id} {...inputs[1]} value={values[inputs[1].name]} onChange={onChange} />
                </div>
              </div>

              <div class="row">
                <div class="column">
                  <FormSelect
                    key={inputs[14].id}
                    name={inputs[14].name}
                    label={inputs[14].label}
                    value={values[inputs[14].name]}
                    ddOptions={ddOptionsCompany}
                    onChange={onChange}
                  />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* CNP */}
              <div class="row">
                <div class="column">
                  <FormInput key={inputs[2].id} {...inputs[2]} value={values[inputs[2].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              {/* Phone and Email */}
              <div class="row">
                <div class="column">
                  <FormInput key={inputs[3].id} {...inputs[3]} value={values[inputs[3].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <FormInput key={inputs[4].id} {...inputs[4]} value={values[inputs[4].name]} onChange={onChange} />
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Detalii tip autorizație deservent">
              {/* Operator Class */}
              <div class="row">
                <div class="column">
                  <FormSelect
                    key={inputs[5].id}
                    name={inputs[5].name}
                    label={inputs[5].label}
                    value={values[inputs[5].name]}
                    ddOptions={operatorTypes}
                    onChange={onChangeOperatorType}
                  />
                </div>
                <div class="column">
                  <FormSelect
                    key={inputs[6].id}
                    name={inputs[6].name}
                    label={inputs[6].label}
                    value={values[inputs[6].name]}
                    ddOptions={equipmentClassifications}
                    onChange={onChangeEquipmentClassification}
                  />
                </div>
              </div>

              {/* Equipment Type and Equipment Group */}
              <div class="row">
                <div class="column">
                  <FormSelect
                    key={inputs[7].id}
                    name={inputs[7].name}
                    label={inputs[7].label}
                    value={values[inputs[7].name]}
                    ddOptions={equipmentClasses}
                    onChange={onChangeEquipmentClass}
                  />
                </div>
                <div class="column">
                  <FormSelectMultiple
                    key={inputs[8].id}
                    name={inputs[8].name}
                    label={inputs[8].label}
                    value={values[inputs[8].name]}
                    ddOptions={equipmentGroups}
                    onChange={onChangeEquipmentGroup}
                  />
                </div>
              </div>

              {/* Registration Number and Registration Issued Date */}
              <div class="row">
                <div class="column">
                  <FormInput key={inputs[9].id} {...inputs[9]} value={values[inputs[9].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <FormInput key={inputs[10].id} {...inputs[10]} value={values[inputs[10].name]} onChange={onChange} />
                </div>
              </div>

              {/* Permit Number and Permit Issued Date */}
              <div class="row">
                <div class="column">
                  <FormInput key={inputs[11].id} {...inputs[11]} value={values[inputs[11].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <FormInput key={inputs[12].id} {...inputs[12]} value={values[inputs[12].name]} onChange={onChange} />
                </div>
              </div>

              {/* Permit Visa Date */}
              <div class="row">
                <div class="column">
                  <FormInput key={inputs[13].id} {...inputs[13]} value={values[inputs[13].name]} onChange={onChange} />
                </div>
                <div class="column">
                  <p>&nbsp;</p>
                </div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf autorizaţie deservent: <input class="fileR" type="file" onChange={(e) => setFileRegistration(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>

              <div class="row">
                <div class="column">
                  Adaugă poză/pdf talon deservent: <input class="fileP" type="file" onChange={(e) => setFilePermit(e.target.files[0])} />
                </div>
                <div class="column">&nbsp;</div>
              </div>
            </AccordionTab>
          </Accordion>
        </div>

        {/* Submit Buttons */}
        <div class="rowdoublecentered">
          <div class="columnbutton">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.OperatorSave)}
              alt="Salvează deservent"
              title="Salvează deservent"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
      <div class="spaceDiv" />
      <Link to={"/operator/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorList)} alt="Listă deservenți" title="Listă deservenți" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.OperatorImport)} alt="Importă deservenți" title="Importă deservenți" />
      </Link>
      <div class="spaceDiv" />
    </div>
  );
}
