import "../static/css/pageLayout.css";

import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { BASE_URL } from "../common/globals";
import FormInput from "../components/FormInput";
import Sidebar from "../components/Sidebar";
import useAuth from "../hooks/useAuth";

export default function AddCountry() {
  const baseURL = BASE_URL + "country/add";

  const { auth } = useAuth();

  const [values, setValues] = useState({
    name: "",
    details: "",
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Tara",
      label: "Nume",
    },
  ];

  const doNothing = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        baseURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
          params: {
            name: values.name,
            userUuid: "892ee26b-d314-11ed-bda1-902b346c1788",
          },
        }
      )
      .then((res) => {
        console.log("Country Added");
        console.log(res.data);
        alert("Tara adaugata");
      });
    // console.log("Submit");
  };

  const onChange = (e) => {
    console.log("onChange");
    console.log(e.target.name);
    console.log(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  console.log(values);
  console.log("render");

  return (
    <div className="clientArea">
      <Sidebar />
      <Link to={"/country/list"}>
        <button class="buttonClassGray">Lista tari</button>
      </Link>
      <button class="buttonClassGreen">Adauga tara</button>
      <button class="buttonClassGray">Import tari</button>

      <form class="formArea" onSubmit={handleSubmit}>
        <div class="row">
          <div class="column">
            <FormInput key={inputs[0].id} {...inputs[0]} value={values[inputs[0].name]} onChange={onChange} />
          </div>
          <div class="column">
            <p>&nbsp;</p>
          </div>
        </div>

        {/* Submit Buttons */}
        <div class="row">
          <div class="column">
            <button class="buttonClassRed" onClick={doNothing}>
              Anuleaza tara
            </button>
          </div>
          <div class="column">
            <button class="buttonClassBlue" onClick={handleSubmit}>
              Salveaza tara
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
