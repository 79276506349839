import React from "react";

import { ICONS } from "../common/globals";

const ProcessFormErrors = (props) => {
  const { validationErrors } = props;

  return (
    <>
      <div class="rowdouble">
        <div class="column">
          <h3 class="labelred">Corectează următoarele erori de validare:</h3>
        </div>
        {validationErrors.map((error, index) => (
          <div class="column">
            <img class="imgdownload" src={require("../static/images/" + ICONS.ValidationError)} alt="Eroare" title="Eroare" />
            &nbsp;&nbsp;
            <a class="imgdownload" href={"#internalLink" + error.id}>
              {error.value}
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProcessFormErrors;
