import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

import { BASE_URL } from "../common/globals";
import Sidebar from "../components/Sidebar";

const baseURL = BASE_URL + "admin/list";

const columns = [
  {
    field: "uuid",
    headerName: "UUID",
    width: 300,
    editable: true,
  },
  {
    field: "clientUuid",
    headerName: "UUID Client",
    width: 300,
    editable: true,
  },
  {
    field: "clientName",
    headerName: "Nume Client",
    width: 150,
    editable: true,
  },
  {
    field: "name",
    headerName: "Nume Firma",
    width: 150,
    editable: true,
  },
  {
    field: "registrationNumber",
    headerName: "Numar Inregistrare",
    width: 150,
    editable: true,
  },
  {
    field: "fiscalCode",
    headerName: "Cod Fiscal",
    width: 150,
  },
  {
    field: "addressUuid",
    headerName: "UUID Adresa",
    width: 150,
    editable: true,
  },
  {
    field: "street",
    headerName: "Strada",
    width: 150,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Telefon",
    width: 150,
    editable: true,
  },
  {
    field: "fax",
    headerName: "Fax",
    width: 150,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    editable: true,
  },
  {
    field: "bankAccount",
    headerName: "IBAN Banca",
    width: 150,
    editable: true,
  },
  {
    field: "bankName",
    headerName: "Nume Banca",
    width: 150,
    editable: true,
  },
  {
    field: "bankLocation",
    headerName: "Sucursala Banca",
    width: 150,
    editable: true,
  },
  {
    field: "contactPerson",
    headerName: "Nume Reprezentant",
    width: 150,
    editable: true,
  },
  {
    field: "contactPersonRole",
    headerName: "Calitate Reprezentant",
    width: 150,
    editable: true,
  },
];

export default function ListCompanies() {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post)
    return (
      <div className="listcompanies">
        <Sidebar />
        <h1>No Post</h1>
      </div>
    );

  return (
    <div className="listcompanies">
      <Sidebar />
      <h1>Lista Iscir</h1>
      <Box sx={{ height: 500, width: "90%" }}>
        <DataGrid
          getRowId={(row) => row.uuid}
          rows={post}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
}
