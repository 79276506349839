// import dependencies
import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function IscirPersonList() {
  const baseURL = BASE_URL + "iscircompanyperson/list";

  // authentication details
  const { auth } = useAuth();

  // for app navigation
  const navigate = useNavigate();

  // state objects
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsWarning, setSelectedRowsWarning] = useState(false);
  const [ddOptionsCompany, setDdOptionsCompany] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewRowDialog, setViewRowDialog] = useState(null);
  const [deleteRowDialog, setDeleteRowDialog] = useState(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    lastName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.CONTAINS },
    phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    authorisation: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // load iscir companies
  useEffect(() => {
    axios
      .post(
        baseURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading iscir companies");
        console.log(error);
        alert("Firmele iscir nu au putut fi încărcate!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Info)}
                alt="Detalii"
                title="Detalii"
                onClick={() => viewRow(rowData)}
              />
            </td>
            <td>&nbsp;&nbsp;</td>
            <td>
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.Delete)}
                alt="Şterge"
                title="Şterge"
                onClick={() => deleteRow(rowData)}
              />
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  };

  const viewRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setViewRowDialog(true);
  };

  const deleteRow = (rowData) => {
    setSelectedRow({ ...rowData });
    setDeleteRowDialog(true);
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Listă persoane ISCIR</button>
      <div class="spaceDiv" />
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAdd)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImport)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirPersonListDisabled)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAdd)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      </Link>
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRows}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          filters={filters}
          filterDisplay="row"
          onSelectionChange={(e) => setSelectedRows(e.value)}
          dataKey="uuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
          <Column field="companyName" filter filterPlaceholder="Caută" sortable header="Firma"></Column>
          <Column field="lastName" filter filterPlaceholder="Caută" sortable header="Nume familie"></Column>
          <Column field="firstName" filter filterPlaceholder="Caută" sortable header="Prenume"></Column>
          <Column field="type" filter filterPlaceholder="Caută" sortable header="Tip"></Column>
          <Column field="phone" filter filterPlaceholder="Caută" sortable header="Telefon"></Column>
          <Column field="email" filter filterPlaceholder="Caută" sortable header="Email"></Column>
          <Column field="authorisation" filter filterPlaceholder="Caută" sortable header="Atestat"></Column>
          <Column field="authorisationIssuedDate" sortable header="Data eliberare atestat"></Column>
          <Column body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAdd)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImport)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirPersonListDisabled)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAdd)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      </Link>
    </div>
  );
}
