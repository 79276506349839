import "../../static/css/pageLayout.css";

import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingDocuments from "../../components/DialogProcessingDocuments";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, dialogFooterContent } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function EquipmentGenerateDocsPV() {
  const instructionsConfigURL = BASE_URL + "equipmentdocuments/get/list/configs";
  const instructionsConfigSaveURL = BASE_URL + "equipmentdocuments/generate/pv";

  const uuid = sessionStorage.getItem("equipmentSessionUuid");

  const navigate = useNavigate();

  const { auth } = useAuth();

  const [configs, setConfigs] = useState([]);

  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);

  let data = [];
  data = { ...data, pvType: "" };
  const [values, setValues] = useState(data);
  const [ddOptionsPV, setDdOptionsPV] = useState([]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const optionsPV = [
    {
      uuid: "1",
      value: "admiterea funcționării la prima punere în funcțiune",
    },
    {
      uuid: "2",
      value: "admiterea funcționării periodic, în exploatare",
    },
    {
      uuid: "3",
      value: "casare echipament",
    },
    {
      uuid: "4",
      value: "conservare, lipsa front de lucru",
    },
    {
      uuid: "5",
      value: "conservare, lipsa personal ISCIR autorizat",
    },
    {
      uuid: "6",
      value: "conservare, lipsa autorizatie ISCIR",
    },
    {
      uuid: "7",
      value: "conservare, echipament defect",
    },
    {
      uuid: "8",
      value: "preluare echipament autorizat",
    },
    {
      uuid: "9",
      value: "preluare echipament neautorizat",
    },
    {
      uuid: "10",
      value: "transfer echipament",
    },
  ];

  useEffect(() => {
    axios
      .post(
        instructionsConfigURL,
        {
          uuid,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        let data = [];
        for (let i = 0; i < response.data.length; i++) {
          data = { ...data, ["rsvtiPerson" + i]: "" };
          data = { ...data, ["contactPerson" + i]: "" };
          data = { ...data, ["pvNumber" + i]: "" };
          data = { ...data, ["pvIssuedDate" + i]: "" };
          data = { ...data, ["expirationDate" + i]: "" };
          data = { ...data, ["takenFromCompany" + i]: "" };
          data = { ...data, ["soldToCompany" + i]: "" };
        }
        setValues({ ...values, ...data });
        setConfigs(response.data);
        setDdOptionsPV(optionsPV);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let data = [];
    configs.map(function (config, index) {
      if (config.rsvtiPersons.length === 1) {
        data = { ...data, ["rsvtiPerson" + index]: config.rsvtiPersons[0].uuid };
      }
      if (config.contactPersons.length === 1) {
        data = { ...data, ["contactPerson" + index]: config.contactPersons[0].uuid };
      }
    });
    setValues({ ...values, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const handleGenerate = async (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    let data = [];
    for (let i = 0; i < configs.length; i++) {
      let newfield = {
        uuid: configs[i].uuid,
        iscirOwnerUuid: values["rsvtiPerson" + i],
        contactUuid: values["contactPerson" + i],
        pvNumber: values["pvNumber" + i],
        pvIssuedDate: values["pvIssuedDate" + i],
        expirationDate: values["expirationDate" + i],
        takenFromCompany: values["takenFromCompany" + i],
        soldToCompany: values["soldToCompany" + i],
      };
      data = [...data, newfield];
    }
    axios
      .post(
        instructionsConfigSaveURL,
        {
          uuid: uuid,
          pvType: values["pvType"],
          configs: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setProcessingDialog(false);
        //setProcessedDialog(true);
        navigate("/equipmentdocuments/generatedocsview");
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  return (
    <div className="clientArea">
      <Sidebar />

      <div class="rowdouble">
        <div class="column">
          <p class="pTitle">Listă evidență instalații ISCIR</p>
        </div>
      </div>

      <div class="rowdouble">
        <div class="column">
          <FormSelect
            key="pvType"
            name="pvType"
            label="Alege tipul de proces verbal"
            value={values["pvType"]}
            ddOptions={ddOptionsPV}
            onChange={onChange}
          />{" "}
        </div>
      </div>

      {configs.map((config, index) => (
        <div key={index * 100}>
          <div class="rowdouble">
            <div class="column">
              <p class="pTitle">
                Punct de lucru {index + 1}: {config.code} - {config.county} - {config.town} - {config.addressDetails1}
              </p>
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="text"
                name={"pvNumber" + index}
                label="Număr proces verbal"
                value={values["pvNumber" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormInput
                key={index * 2 + 1}
                type="date"
                name={"pvIssuedDate" + index}
                label="Dată emitere proces verbal"
                value={values["pvIssuedDate" + index]}
                onChange={onChange}
              />
            </div>
          </div>
          {null != values["pvType"] && ("1" === values["pvType"] || "2" === values["pvType"]) ? (
            <div class="rowdouble">
              <div class="column">
                <FormInput
                  key={index * 2 + 1}
                  type="date"
                  name={"expirationDate" + index}
                  label="Dată scadenţă"
                  value={values["expirationDate" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
          {null != values["pvType"] && ("8" === values["pvType"] || "9" === values["pvType"]) ? (
            <div class="rowdouble">
              <div class="column">
                <FormInput
                  key={index * 2 + 1}
                  type="text"
                  name={"takenFromCompany" + index}
                  label="Nume firmă de la care a fost preluat echipamentul"
                  value={values["takenFromCompany" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
          {null != values["pvType"] && "10" === values["pvType"] ? (
            <div class="rowdouble">
              <div class="column">
                <FormInput
                  key={index * 2 + 1}
                  type="text"
                  name={"soldToCompany" + index}
                  label="Nume firmă la care a fost transferat echipamentul"
                  value={values["soldToCompany" + index]}
                  onChange={onChange}
                />
              </div>
            </div>
          ) : null}
          <div class="rowdouble">
            <div class="column">
              <FormSelect
                key={index * 2}
                name={"rsvtiPerson" + index}
                label="Operator RSVTI"
                value={values["rsvtiPerson" + index]}
                ddOptions={config.rsvtiPersons}
                onChange={onChange}
              />
            </div>
          </div>
          <div class="rowdouble">
            <div class="column">
              <FormSelect
                key={index * 2}
                name={"contactPerson" + index}
                label="Responsabil punct de lucru"
                value={values["contactPerson" + index]}
                ddOptions={config.contactPersons}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      ))}

      {/* Submit Buttons */}
      {processingDialog !== true ? (
        <div class="rowdouble">
          <div class="column">
            <button class="longButtonClassGray" onClick={handleGenerate}>
              Generare procese verbale RSVTI
            </button>
          </div>
        </div>
      ) : null}

      <DialogProcessingDocuments isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
