// import dependencies
import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, dialogHeaderElement, dialogFooterContent, dialogFooterContentTwoButtons } from "../../common/globals";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ImportIscirCompanies() {
  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Importă firme ISCIR</button>
      <div class="spaceDiv" />
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAdd)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImportDisabled)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonList)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAdd)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      </Link>
    </div>
  );
}
