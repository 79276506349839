import "../../static/css/pageLayout.css";

import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DialogConfigurationNotProcessed from "../../components/DialogConfigurationNotProcessed";
import DialogProcessingData from "../../components/DialogProcessingData";
import DialogDataProcessed from "../../components/DialogDataProcessed";
import DialogDataNotProcessed from "../../components/DialogDataNotProcessed";
import { BASE_URL, ICONS, dialogFooterContent } from "../../common/globals";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function AddIscirCompany() {
  const baseURL = BASE_URL + "iscircompany/add";
  const countyListURL = BASE_URL + "county/list";
  const townListURL = BASE_URL + "town/listbycounty";
  const companyListURL = BASE_URL + "iscircompany/main";
  const structureConfigURL = BASE_URL + "iscircompany/config";
  const companyPersonsByCompanyURL = BASE_URL + "iscircompanyperson/allbycompany";

  const [ddOptionsCounty, setDdOptionsCounty] = useState([]);
  const [ddOptionsTown, setDdOptionsTown] = useState([]);
  const [ddOptionsIscirCompany, setDdOptionsIscirCompany] = useState([]);
  const [noOfAuth, setNoOfAuth] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [rslPersons, setRslPersons] = useState([]);
  const [radtpPersons, setRadtpPersons] = useState([]);
  const [radtePersons, setRadtePersons] = useState([]);
  const [processingDialog, setProcessingDialog] = useState(null);
  const [processedDialog, setProcessedDialog] = useState(null);
  const [notProcessedDialog, setNotProcessedDialog] = useState(null);
  const [configNotProcessedDialog, setConfigNotProcessedDialog] = useState(null);

  const { auth } = useAuth();

  const [values, setValues] = useState({
    iscirCompanyUuid: "",
    name: "",
    countyUuid: "",
    townUuid: "",
    authorisationNumber0: "",
    prescriptionUuid0: "",
  });

  const inputs = [
    {
      id: 0,
      name: "iscirCompanyUuid",
      type: "selectClass",
      placeholder: "Firmă ISCIR",
      label: "Firmă ISCIR",
    },
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Nume",
      label: "Nume",
    },
    {
      id: 2,
      name: "countyUuid",
      type: "selectClass",
      placeholder: "Județ/sector",
      label: "Județ/sector",
    },
    {
      id: 3,
      name: "townUuid",
      type: "selectClass",
      placeholder: "Localitate",
      label: "Localitate",
    },
  ];

  // load config
  useEffect(() => {
    axios
      .post(
        structureConfigURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setConfigs(response.data);
        setPrescriptions(response.data.lifting);
        setValues({ ...values, companyType: "lifting" });
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .get(countyListURL, {
        headers: { Authorization: `Bearer ${auth.token}` },
      })
      .then((response) => {
        setDdOptionsCounty(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    axios
      .post(
        companyListURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsIscirCompany(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCounty = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value, townUuid: "" });
    setDdOptionsTown([]);
    axios
      .post(
        townListURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setDdOptionsTown(response.data);
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessingDialog(true);
    let data = [];
    for (let i = 0; i < noOfAuth.length; i++) {
      // check if valid authorisation
      if (null !== values["authorisationNumber" + i] && "" !== values["authorisationNumber" + i]) {
        for (let index = 0; index < prescriptions.length; index++) {
          if (values["prescriptionUuid" + i] === prescriptions[index].uuid) {
            let dataActivities = [];
            for (let j = 0; j < prescriptions[index].activities.length; j++) {
              //check if activity is selected
              if (values["activity-" + i + "-" + j] === prescriptions[index].activities[j].uuid) {
                let dataPersons = [];
                if (null !== prescriptions[index].activities[j].persons) {
                  for (let k = 0; k < prescriptions[index].activities[j].persons.length; k++) {
                    let newfield = {
                      personUuid: values["person-" + i + "-" + j + "-" + k],
                      lastName: values["lastName-" + i + "-" + j + "-" + k],
                      firstName: values["firstName-" + i + "-" + j + "-" + k],
                      phone: values["phone-" + i + "-" + j + "-" + k],
                      email: values["email-" + i + "-" + j + "-" + k],
                      authorisation: values["authorisation-" + i + "-" + j + "-" + k],
                      authorisationIssuedDate: values["authorisationIssuedDate-" + i + "-" + j + "-" + k],
                      typeUuid: values["typeUuid-" + i + "-" + j + "-" + k],
                    };
                    dataPersons = [...dataPersons, newfield];
                  }
                }
                let newfield = {
                  activityUuid: values["activity-" + i + "-" + j],
                  persons: dataPersons,
                };
                dataActivities = [...dataActivities, newfield];
              }
            }
            let newfield = {
              authorisationNumber: values["authorisationNumber" + i],
              prescriptionUuid: values["prescriptionUuid" + i],
              activities: dataActivities,
            };
            data = [...data, newfield];
          }
        }
      }
    }
    axios
      .post(
        baseURL,
        {
          iscirCompanyUuid: values.iscirCompanyUuid,
          name: values.name,
          countyUuid: values.countyUuid,
          townUuid: values.townUuid,
          authorisations: data,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((res) => {
        setProcessingDialog(false);
        setProcessedDialog(true);
      })
      .catch((error) => {
        setProcessingDialog(false);
        setNotProcessedDialog(true);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeCompany = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setRslPersons([]);
    setRadtpPersons([]);
    setRadtePersons([]);
    axios
      .post(
        companyPersonsByCompanyURL,
        {
          uuid: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if ("b3062a17-175a-11ef-b2d9-74563c1b28c3" === response.data[i].uuid) {
            setRslPersons(response.data[i].persons);
          } else if ("de848ff1-1564-11ef-8523-74563c1b28c3" === response.data[i].uuid) {
            setRadtpPersons(response.data[i].persons);
          } else if ("a0a5cb74-175a-11ef-b2d9-74563c1b28c31" === response.data[i].uuid) {
            setRadtePersons(response.data[i].persons);
          }
        }
      })
      .catch((error) => {
        setConfigNotProcessedDialog(true);
      });
  };

  const onChangePrescription = (e, index) => {
    let activities = [];
    let data = {};
    for (let i = 0; i < prescriptions.length; i++) {
      if (prescriptions[i].uuid === e.target.value) {
        activities = prescriptions[i].activities;
        for (let j = 0; j < activities.length; j++) {
          data["activity-" + index + "-" + j] = "";
          if (null !== activities[j].persons) {
            for (let k = 0; k < activities[j].persons.length; k++) {
              data["person-" + index + "-" + j + "-" + k] = "";
              data["lastName-" + index + "-" + j + "-" + k] = "";
              data["firstName-" + index + "-" + j + "-" + k] = "";
              data["email-" + index + "-" + j + "-" + k] = "";
              data["phone-" + index + "-" + j + "-" + k] = "";
              data["authorisation-" + index + "-" + j + "-" + k] = "";
              data["authorisationIssuedDate-" + index + "-" + j + "-" + k] = "";
              data["typeUuid-" + index + "-" + j + "-" + k] = activities[j].persons[k].uuid;
            }
          }
        }
      }
    }
    noOfAuth[index] = activities;
    setValues({ ...values, ...data, [e.target.name]: e.target.value });
  };

  const addAuth = (e) => {
    e.preventDefault();
    if (noOfAuth.length < 10) {
      setValues({
        ...values,
        ["authorisationNumber" + noOfAuth.length]: "",
        ["prescriptionUuid" + noOfAuth.length]: "",
      });
      setNoOfAuth([...noOfAuth, []]);
    }
  };

  const onChangeCheckBox = (e) => {
    if (e.target.checked) {
      setValues({ ...values, [e.target.name]: e.target.value });
    } else {
      setValues({ ...values, [e.target.name]: "" });
    }
  };

  const onChangeRadio = (e) => {
    if (e.target.value === "lifting") {
      setPrescriptions(configs.lifting);
    } else {
      setPrescriptions(configs.pressure);
    }
    let data = {};
    for (let i = 0; i < noOfAuth.length; i++) {
      data["authorisationNumber" + i] = "";
      data["prescriptionUuid" + i] = "";
    }
    setValues({ ...values, ...data, [e.target.name]: e.target.value });
    setNoOfAuth([]);
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Adaugă firmă ISCIR</button>
      <div class="spaceDiv" />
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAddDisabled)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImport)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonList)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAdd)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      </Link>
      <form class="formArea">
        <div class="rowdouble">
          <div class="row">
            <div class="column">
              <FormSelect
                key={inputs[0].id}
                name={inputs[0].name}
                label={inputs[0].label}
                value={values[inputs[0].name]}
                ddOptions={ddOptionsIscirCompany}
                onChange={onChangeCompany}
              />
            </div>
            <div class="column">
              <p>&nbsp;</p>
            </div>
          </div>

          <div class="row">
            <div class="column">
              <RadioButton
                inputId="liftingRadio"
                name="companyType"
                value="lifting"
                onChange={onChangeRadio}
                checked={values["companyType"] === "lifting"}
              />
              <label htmlFor="liftingRadio" className="flex align-items-center">
                &nbsp;&nbsp;Echipamente de ridicat
              </label>
            </div>
            <div class="column">
              <RadioButton
                inputId="pressureRadio"
                name="companyType"
                value="pressure"
                onChange={onChangeRadio}
                checked={values["companyType"] === "pressure"}
              />
              <label htmlFor="pressureRadio" className="flex align-items-center">
                &nbsp;&nbsp;Echipamente sub presiune
              </label>
            </div>
          </div>

          <div class="row">
            <div class="column">
              <FormInput key={inputs[1].id} {...inputs[1]} value={values[inputs[1].name]} onChange={onChange} />
            </div>
            <div class="column">
              <p>&nbsp;</p>
            </div>
          </div>

          <div class="row">
            <div class="column">
              <FormSelect
                key={inputs[2].id}
                name={inputs[2].name}
                label={inputs[2].label}
                value={values[inputs[2].name]}
                ddOptions={ddOptionsCounty}
                onChange={onChangeCounty}
              />
            </div>
            <div class="column">
              <FormSelect
                key={inputs[3].id}
                name={inputs[3].name}
                label={inputs[3].label}
                value={values[inputs[3].name]}
                ddOptions={ddOptionsTown}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        {noOfAuth.map((auth, index) => (
          <>
            <div class="row">
              <div class="column">
                <FormInput
                  key={index + 3001}
                  name={"authorisationNumber" + index}
                  value={values["authorisationNumber" + index]}
                  type="text"
                  label={"Număr autorizaţie"}
                  onChange={onChange}
                />
              </div>
              <div class="column">
                <FormSelect
                  key={index + 4001}
                  name={"prescriptionUuid" + index}
                  label={"Prescripţie"}
                  value={values["prescriptionUuid" + index]}
                  ddOptions={prescriptions}
                  onChange={(e) => {
                    onChangePrescription(e, index);
                  }}
                />
              </div>
            </div>
            {auth.map((activity, index2) => (
              <>
                <div class="row">
                  <div class="column">
                    <Checkbox
                      key={activity.uuid + "-" + index2}
                      inputId={activity.value}
                      name={"activity-" + index + "-" + index2}
                      value={activity.uuid}
                      onChange={onChangeCheckBox}
                      checked={values["activity-" + index + "-" + index2] === activity.uuid}
                    />
                    <label htmlFor={activity.value} className="flex align-items-center">
                      &nbsp;&nbsp;{activity.value}
                    </label>
                  </div>
                  <div class="column"></div>
                </div>
                {values["activity-" + index + "-" + index2] === activity.uuid ? (
                  <>
                    {null !== activity.persons ? (
                      <>
                        {activity.persons.map((person, index3) => (
                          <>
                            <div class="row">
                              {"b3062a17-175a-11ef-b2d9-74563c1b28c3" === person.uuid ? (
                                <div class="column">
                                  <FormSelect
                                    key={"uuid" + person.uuid + "-" + index3}
                                    name={"person-" + index + "-" + index2 + "-" + index3}
                                    label={person.value + "Person"}
                                    value={values["person-" + index + "-" + index2 + "-" + index3]}
                                    ddOptions={rslPersons}
                                    onChange={onChange}
                                  />
                                </div>
                              ) : null}
                              {"a0a5cb74-175a-11ef-b2d9-74563c1b28c3" === person.uuid ? (
                                <div class="column">
                                  <FormSelect
                                    key={"uuid" + person.uuid + "-" + index3}
                                    name={"person-" + index + "-" + index2 + "-" + index3}
                                    label={person.value + "Person"}
                                    value={values["person-" + index + "-" + index2 + "-" + index3]}
                                    ddOptions={radtePersons}
                                    onChange={onChange}
                                  />
                                </div>
                              ) : null}
                              {"de848ff1-1564-11ef-8523-74563c1b28c3" === person.uuid ? (
                                <div class="column">
                                  <FormSelect
                                    key={"uuid" + person.uuid + "-" + index3}
                                    name={"person-" + index + "-" + index2 + "-" + index3}
                                    label={person.value + "Person"}
                                    value={values["person-" + index + "-" + index2 + "-" + index3]}
                                    ddOptions={radtpPersons}
                                    onChange={onChange}
                                  />
                                </div>
                              ) : null}
                              <div class="column"></div>
                            </div>
                            <div class="row">
                              <div class="column">
                                <FormInput
                                  key={"lastName" + person.uuid + "-" + index3}
                                  name={"lastName-" + index + "-" + index2 + "-" + index3}
                                  value={values["lastName-" + index + "-" + index2 + "-" + index3]}
                                  onChange={onChange}
                                  placeholder={person.value + " Nume familie"}
                                  label={person.value + " Nume familie"}
                                  type="text"
                                />
                              </div>
                              <div class="column">
                                <FormInput
                                  key={"firstName" + person.uuid + "-" + index3}
                                  name={"firstName-" + index + "-" + index2 + "-" + index3}
                                  value={values["firstName-" + index + "-" + index2 + "-" + index3]}
                                  onChange={onChange}
                                  placeholder={person.value + " Prenume"}
                                  label={person.value + " Prenume"}
                                  type="text"
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="column">
                                <FormInput
                                  key={"phone" + person.uuid + "-" + index3}
                                  name={"phone-" + index + "-" + index2 + "-" + index3}
                                  value={values["phone-" + index + "-" + index2 + "-" + index3]}
                                  onChange={onChange}
                                  placeholder={person.value + " Număr telefon"}
                                  label={person.value + " Număr telefon"}
                                  type="text"
                                />
                              </div>
                              <div class="column">
                                <FormInput
                                  key={"email" + person.uuid + "-" + index3}
                                  name={"email-" + index + "-" + index2 + "-" + index3}
                                  value={values["email-" + index + "-" + index2 + "-" + index3]}
                                  onChange={onChange}
                                  placeholder={person.value + " Adresă e-mail"}
                                  label={person.value + " Adresă e-mail"}
                                  type="text"
                                />
                              </div>
                            </div>
                            {"a0a5cb74-175a-11ef-b2d9-74563c1b28c3" === person.uuid || "de848ff1-1564-11ef-8523-74563c1b28c3" === person.uuid ? (
                              <div class="row">
                                <div class="column">
                                  <FormInput
                                    key={"authorisation" + person.uuid + "-" + index3}
                                    name={"authorisation-" + index + "-" + index2 + "-" + index3}
                                    value={values["authorisation-" + index + "-" + index2 + "-" + index3]}
                                    onChange={onChange}
                                    placeholder={person.value + " Atestat"}
                                    label={person.value + " Atestat"}
                                    type="text"
                                  />
                                </div>
                                <div class="column">
                                  <FormInput
                                    key={"authorisationIssuedDate" + person.uuid + "-" + index3}
                                    name={"authorisationIssuedDate-" + index + "-" + index2 + "-" + index3}
                                    value={values["authorisationIssuedDate-" + index + "-" + index2 + "-" + index3]}
                                    onChange={onChange}
                                    placeholder={person.value + " Data eliberare/reautorizare atestat"}
                                    label={person.value + " Data eliberare/reautorizare atestat"}
                                    type="date"
                                  />
                                </div>
                              </div>
                            ) : null}
                          </>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ))}
          </>
        ))}
        <div class="row">
          <div class="column">
            <img
              class="imgtoolbar"
              src={require("../../static/images/buttons/" + ICONS.IscirCompanyAuthorisationAdd)}
              alt="Adaugă autorizaţie (maxim 10)"
              title="Adaugă autorizaţie (maxim 10)"
              onClick={addAuth}
            />
          </div>
          <div class="column">&nbsp;</div>
        </div>

        {processingDialog !== true ? (
          <div class="rowdoublecentered">
            <div class="columnbutton">
              <img
                class="imgtoolbar"
                src={require("../../static/images/buttons/" + ICONS.IscirCompanySave)}
                alt="Salvează echipament"
                title="Salvează echipament"
                onClick={handleSubmit}
              />
            </div>
          </div>
        ) : null}
      </form>
      <Link to={"/iscircompany/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyList)} alt="Listă firme ISCIR" title="Listă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyAddDisabled)} alt="Adaugă firmă ISCIR" title="Adaugă firmă ISCIR" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscircompany/import"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirCompanyImport)} alt="Importă firme ISCIR" title="Importă firme ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/list"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonList)} alt="Listă persoane ISCIR" title="Listă persoane ISCIR" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/iscirperson/add"}>
        <img src={require("../../static/images/buttons/" + ICONS.IscirPersonAdd)} alt="Adaugă persoană ISCIR" title="Adaugă persoană ISCIR" />
      </Link>
      <DialogProcessingData isVisible={processingDialog} />
      <DialogDataProcessed isVisible={processedDialog} footer={dialogFooterContent("OK", () => setProcessedDialog(false))} />
      <DialogDataNotProcessed isVisible={notProcessedDialog} footer={dialogFooterContent("OK", () => setNotProcessedDialog(false))} />
      <DialogConfigurationNotProcessed
        isVisible={configNotProcessedDialog}
        footer={dialogFooterContent("OK", () => setConfigNotProcessedDialog(false))}
      />
    </div>
  );
}
