import "./RegisterUser.css";

import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BASE_URL } from "../common/globals";
import useAuth from "../hooks/useAuth";
import Footer from "../public/Footer";
import Header from "../public/Header";

const LoginUser = () => {
  const LOGIN_URL = BASE_URL + "authenticate/login";

  const navigate = useNavigate();

  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(LOGIN_URL, {
        username: user,
        password: pwd,
      });
      console.log(JSON.stringify(response?.data));
      const token = response?.data?.token;
      const roles = [response?.data?.role];
      setAuth({ user, roles, token });
      setUser("");
      setPwd("");
      navigate("/client/statistics");
    } catch (err) {
      alert("error");
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <section>
          <h1>You are logged in!</h1>
          <br />
          <p>
            <Link to={"/#"}>Pagina principală</Link>
          </p>
        </section>
      ) : (
        <div class="tothemiddle">
          <Header />
          <form onSubmit={handleSubmit}>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
              {errMsg}
            </p>
            <br />
            <h1>Conectare în MyRSVTI</h1>
            <label htmlFor="username">Email:</label>
            <br />
            <input type="text" id="username" ref={userRef} autoComplete="off" onChange={(e) => setUser(e.target.value)} value={user} required />
            <br />
            <br />
            <label htmlFor="password">Parola:</label>
            <br />
            <input type="password" id="password" onChange={(e) => setPwd(e.target.value)} value={pwd} required />
            <br />
            <br />
            <button>Autentificare</button>
            <p>
              <br />
              <br />
              Nu ai cont?
              <br />
              <br />
              <span className="line">
                <Link to={"/public/registerclient"}>Creează cont</Link>
                <br />
                <br />
                <Link to={"/#"}>Pagina principală</Link>
              </span>
            </p>
          </form>
          <Footer />
        </div>
      )}
    </>
  );
};

export default LoginUser;
