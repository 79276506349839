// import dependencies
import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import custom code
import { BASE_URL, ICONS, warningHeaderElement } from "../../common/globals";
import Sidebar from "../../components/Sidebar";
import useAuth from "../../hooks/useAuth";

export default function ListEquipmentSessions() {
  const baseURL = BASE_URL + "equipment/session/list";

  // state objects
  const [list, setList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowWarning, setSelectedRowWarning] = useState(false);

  // authentication details
  const { auth } = useAuth();

  // for app navigation
  const navigate = useNavigate();

  // footer of the selected rows warning dialog
  const footerContent = (
    <div>
      <Button label="Ok" icon="pi pi-check" onClick={() => setSelectedRowWarning(false)} autoFocus />
    </div>
  );

  // load equipment generated documents sessions
  useEffect(() => {
    axios
      .post(
        baseURL,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      )
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log("Error loading equipment sessions");
        console.log(error);
        alert("Documentele echipamentelor nu au putut fi încărcate!");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // view equipment generated documents status for selected session
  const handleGenerate = async (e) => {
    e.preventDefault();
    if (selectedRow === null || selectedRow.length === 0) {
      setSelectedRowWarning(true);
      return;
    }
    sessionStorage.setItem("equipmentSessionUuid", selectedRow.sessionUuid);
    navigate("/equipmentdocuments/generatedocsview");
  };

  return (
    <div className="clientArea">
      <Sidebar />
      <button class="buttonClassGreen">Documente echipamente</button>
      <div class="spaceDiv" />
      <Link to={"/company/sessions"}>
        <img src={require("../../static/images/buttons/" + ICONS.CompanyGenerateDocuments)} alt="Documente firmă" title="Documente firmă" />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/operator/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.OperatorGenerateDocuments)}
          alt="Documente reautorizări deservenți"
          title="Documente reautorizări deservenți"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Link to={"/worker/sessions"}>
        <img
          src={require("../../static/images/buttons/" + ICONS.WorkerGenerateDocuments)}
          alt="Documente certificări deservenți auxiliari"
          title="Documente certificări deservenți auxiliari"
        />
      </Link>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        src={require("../../static/images/buttons/" + ICONS.EquipmentLiftingGenerateDocumentsDisabled)}
        alt="Documente echipamente"
        title="Documente echipamente"
      />
      <div class="spaceDiv" />
      <div class="card">
        <DataTable
          value={list}
          selectionMode={null}
          selection={selectedRow}
          stripedRows
          paginator
          rows={25}
          rowsPerPageOptions={[10, 25, 50]}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="sessionUuid"
          tableStyle={{ minWidth: "50rem" }}
        >
          <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
          <Column field="type" sortable header="Tip documentaţie echipamente"></Column>
          <Column field="date" sortable dataType="date" header="Data"></Column>
        </DataTable>
      </div>
      <button class="longButtonClassGray" onClick={handleGenerate}>
        Vezi documente echipamente
      </button>
      <Dialog
        visible={selectedRowWarning}
        modal
        header={warningHeaderElement}
        footer={footerContent}
        style={{ width: "50rem" }}
        onHide={() => setSelectedRowWarning(false)}
      >
        <p className="m-0">Selectează o documentaţie pentru echipamente!</p>
      </Dialog>
    </div>
  );
}
